import { useIntl } from 'react-intl';
import Accordion from 'react-bootstrap/Accordion';
import { isArabic } from 'i18n';
import './Glossary.scss';

const Glossary = () => {
  const { locale } = useIntl();

  return (
    <div className="glossary-container">
      {!isArabic(locale) && <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            A
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            <p><b>Account Balance:</b> Account balance refers to the amount in a bank/cryptocurrency account that can be accessed immediately. On the other hand, in accounting, account balance refers to the sum obtained from the difference between all debit and credit transactions posted to a company’s account.</p>
            <p><b>Accumulation Phase:</b> The accumulation phase is a stage in the market cycle right after a downtrend, where the institutional investors start buying in tranches, signaling a positive uptrend soon.</p>
            <p><b>Acquisition:</b> An acquisition is one company buying out another company by purchasing a controlling stake of over 50%</p>
            <p><b>Active Management:</b> Different strategies deployed by investors to generate alpha to outperform the market.</p>
            <p><b>Address:</b> A place where cryptocurrency tokens can be sent to and from, which is usually denoted in the form of a string of letters and numbers</p>
            <p><b>Airdrop:</b> A marketing campaign that distributes a specific cryptocurrency or token to an audience for being early users or adapters of that network/token.</p>
            <p><b>Algorithm:</b> A process or set of rules written in the form of a code to be followed in problem-solving or calculation operations, usually by a computer.</p>
            <p><b>All time high:</b> The highest price that an asset has achieved on an exchange, for the current trading pair that is being referenced</p>
            <p><b>All time Low:</b> The lowest price that an asset has achieved on an exchange, for the current trading pair that is being referenced.</p>
            <p><b>Alpha:</b> Alpha is a financial tool indicating an investment’s performance relative to its benchmark index in the market.</p>
            <p><b>Altcoin:</b> Every other cryptocurrency other than bitcoin.</p>
            <p><b>Angel Investor:</b> Wealthy investors that actively seek out opportunities to provide funding for entrepreneurs or start-up companies.</p>
            <p><b>Anonymous:</b> Unknown or hidden identity of someone or something</p>
            <p><b>AML:</b> Anti-money-laundering is a set of international laws enacted to stop criminals from laundering money through cryptocurrencies into real-world cash.</p>
            <p><b>API:</b> Application Programming Interface is a set of routines, protocols, and tools for building software applications.</p>
            <p><b>Arbitrage:</b> Risk free profit by taking advantage of price difference between various marketplaces.</p>
            <p><b>ASIC:</b> Application-specific integrated circuit (ASIC) is a device designed for the sole purpose of mining cryptocurrencies, especially bitcoin.</p>
            <p><b>Asset under management:</b> Total value of all funds controlled by a fund manager/institution on behalf of its clients.</p>
            <p><b>Atomic swap:</b> The transfer of cryptocurrency from one party to another, without the use of an exchange or other intermediary.</p>
            <p><b>Atomic Dex:</b> Atomic Dex offers a cryptocurrency wallet and DEX in one application that is available for multiple platforms.</p>
            <p><b>Audit:</b> A process where developers inspect the underlying code and/or algorithm that compose systems and applications. Also refers to an official inspection of an organization's accounts, typically by an independent body.</p>
            <p><b>Augmented Reality:</b> A a system that combines the real and virtual worlds and allows real-time interaction and precise 3D registration of virtual objects.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            B
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            C
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            D
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            E
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            F
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            G
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            H
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            I
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>
            J
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>
            K
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11">
          <Accordion.Header>
            L
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="12">
          <Accordion.Header>
            M
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="13">
          <Accordion.Header>
            N
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="14">
          <Accordion.Header>
            O
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="15">
          <Accordion.Header>
            P
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="16">
          <Accordion.Header>
            Q
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="17">
          <Accordion.Header>
            R
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="18">
          <Accordion.Header>
            S
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="19">
          <Accordion.Header>
            T
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="20">
          <Accordion.Header>
            U
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="21">
          <Accordion.Header>
            V
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="22">
          <Accordion.Header>
            W
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="23">
          <Accordion.Header>
            X
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="24">
          <Accordion.Header>
            Y
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="25">
          <Accordion.Header>
            Z
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>}
      {isArabic(locale) && <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            أ
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            <p>إدارة نشطة.: استراتيجيات مختلفة يضعها المستثمرون لتوليد دفعة الانطلاق للتفوق على السوق</p>
            <p>ارتفاع قياسي: أعلى سعر يحققه الأصل في سوق التبادل لزوج التداول الحالي الذي تتم الإشارة إليه</p>
            <p>استحواذ ٪: هو قيام شركة بشراء شركة أخرى عن طريق شراء حصة مسيطرة تزيد عن 50</p>
            <p>إسقاط العملة المشفرة (Airdrop): حملة تسويقية يتم فيها توزيع عملة مشفرة أو رمزًا مميزًا للجمهور بصفتهم من المستخدمين أو المواكبين الأوائل لتلك الشبكة/الرمز المميز..</p>
            <p>أصول خاضعة للإدارة: لقيمة الإجمالية لجميع الأصول التي يسيطر عليها مدير صندوق/مؤسسة تمويل نيابة عن عملائها.</p>
            <p>اعرف عميلك (KYC):اعرف عميلك  هي عملية تستخدمها المؤسسات المالية للتحقق من هوية عملائها. تتضمن عملية اعرف عميلك  جمع معلومات شخصية عن العملاء، مثل الاسم والعنوان وتاريخ الميلاد ومعلومات شخصية أخرى. إن الهدف من هذه العملية مساعدة المصارف والمؤسسات المالية الأخرى في الكشف عن عمليات غسل الأموال وتمويل الإرهاب والاحتيال ومنعها..</p>
            <p>هامش العرض-الطلب:الفرق بين أعلى سعر يحدده المشتري مقابل أصل وأدنى سعر يقبله البائع</p>
            <p>انخفاض قياسي:أدنى سعر يحققه الأصل في سوق التبادل لزوج التداول الحالي الذي تتم الإشارة إليه</p>
            <p>الإنفاق المزدوج:الإنفاق المزدوج هو شكل من أشكال الاحتيال يتم فيه إنفاق العملة المشفرة أكثر من مرة. في حالة العملة المشفرة، يحدث الإنفاق المزدوج عندما يحاول المستخدم إرسال العملة المشفرة نفسها من عنوان واحد إلى جهات مختلفة في الوقت عينه. في الحقيقة، إن هذا الأمر ممكن لأن العملة المشفرة ليست مضمونة فعليًا من قبل سلطة مركزية، وهي تعتمد بدلًا من ذلك على نظام سجل الحسابات اللّامركزي مثل البلوك تشين.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            ب
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            ت
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            ث
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            ج
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            ح
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            خ
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            د
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            ر
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>
            س
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>
            ع
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11">
          <Accordion.Header>
            ك
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="12">
          <Accordion.Header>
            م
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="13">
          <Accordion.Header>
            ن
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="14">
          <Accordion.Header>
            ه
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="15">
          <Accordion.Header>
            و
            <i className="glossary-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body>
            TBD
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>}
    </div>
  );
}

export default Glossary;
