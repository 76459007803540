import { Col } from 'react-bootstrap';

export const LegalColLeft = ({ children }) => (
  <Col className="p-1 legal-col-left" xs={12} lg={2}>
    {children}
  </Col>
);

export const LegalColRight = ({ children }) => (
  <Col className="p-1 legal-col-right" xs={12} lg={10}>
    {children}
  </Col>
);
