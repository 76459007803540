import { Outlet, useLocation } from 'react-router-dom';
import { Footer, Header } from 'components';

const WITHOUT_FOOTER = ['/academy'];

const Layout = ({ setLocale }) => {
  const { pathname } = useLocation();

  return (
    <>
      <Header setLocale={setLocale} />
      <Outlet />
      {!WITHOUT_FOOTER.includes(pathname) && <Footer />}
    </>
  );
};

export default Layout;