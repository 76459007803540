import { LOCALES } from 'i18n/constants';
import en from 'i18n/translations/en.json';
import ar from 'i18n/translations/ar.json';


const translations = {
  [LOCALES.en]: en,
  [LOCALES.ar]: ar,
};

export default translations;
