import { useIntl } from 'react-intl';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isArabic, translate } from 'i18n';
import 'styles/components/MoreFeatures.scss';

function MoreFeatures() {
  const { locale } = useIntl();

  return (
    <section className="more-features">
      <h3>{translate('landing.moreFeatures.title')}</h3>
      <div className="slider-wrapper">
        <Swiper
          modules={[Autoplay]}
          className="more-features-slider"
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
            reverseDirection: isArabic(locale)
          }}
          loop={true}
          slidesPerView="auto"
          speed={8000}
          loopedSlides={4}
          spaceBetween={30}
        >
          <SwiperSlide className={`card-custom item1 ${isArabic(locale) ? 'arabic' : ''}`}>
            <div className="card-content">
              <div>
                <img src={require('assets/images/feature1.png')} alt="feature1" />
              </div>
            </div>
            <p className="card-footer">
              {translate('landing.moreFeatures.p1')}
            </p>
          </SwiperSlide>
          <SwiperSlide className={`card-custom item2 big ${isArabic(locale) ? 'arabic' : ''}`}>
            <div className="card-content">
              <div>
                <img src={require('assets/images/feature2.png')} alt="feature2" />
              </div>
            </div>
            <p className="card-footer">
              {translate('landing.moreFeatures.p2')}
            </p>
          </SwiperSlide>
          <SwiperSlide className={`card-custom item3 ${isArabic(locale) ? 'arabic' : ''}`}>
            <div className="card-content">
              <div></div>
            </div>
            <p className="card-footer-justified">
              {translate('landing.moreFeatures.p3')}
            </p>
            <div className="stub"></div>
          </SwiperSlide>
          <SwiperSlide className={`card-custom item4 ${isArabic(locale) ? 'arabic' : ''}`}>
            <div className="card-content">
              <div>
                <img src={require('assets/images/feature4.png')} alt="feature2" />
              </div>
            </div>
            <p className="card-footer">
              {translate('landing.moreFeatures.p4')}
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="slider-wrapper">
        <Swiper
            modules={[Autoplay]}
            className="more-features-slider"
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              reverseDirection: !isArabic(locale),
            }}
            loop={true}
            slidesPerView="auto"
            loopedSlides={3}
            spaceBetween={30}
            speed={8000}
        >
          <SwiperSlide className={`card-custom item5 big ${isArabic(locale) ? 'arabic' : ''}`}>
            <div className="card-content">
              <div>
                <img src={require('assets/images/feature5.png')} alt="feature5" />
              </div>
            </div>
            <p className="card-footer">
              {translate('landing.moreFeatures.p5')}
            </p>
          </SwiperSlide>
          <SwiperSlide className={`card-custom item6 ${isArabic(locale) ? 'arabic' : ''}`}>
            <div className="card-custom item6">
              <div className="card-content">
                <img src={require('assets/images/feature6.png')} alt="feature6" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className={`card-custom item7 big ${isArabic(locale) ? 'arabic' : ''}`}>
            <div className="card-content">
              <div>
                <img src={require('assets/images/feature7.png')} alt="feature7" />
              </div>
            </div>
            <p className="card-footer">
              {translate('landing.moreFeatures.p6')}
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default MoreFeatures;
