import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LOCALES } from 'i18n/constants';

export const translate = (id, values = {}) => (
  <FormattedMessage id={id} values={{...values}} />
);

export const isBrowserLanguageArabic = () => {
  // Get the user's language preferences from the browser
  const languages = navigator.languages;

  // Check if any of the user's languages are Arabic
  // Arabic has the ISO 639-1 code "ar"
  return languages.some(language => language.startsWith('ar'));
};

export const isArabic = (locale) => {
  return locale === LOCALES.ar;
};
