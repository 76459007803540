import { useIntl } from 'react-intl';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CardText, CardTitle } from 'components';
import { RewardsIcon1, RewardsIcon2, RewardsIcon3 } from 'icons';
import { isArabic, translate } from 'i18n';
import './RewardsSlider.scss';

const RewardsSlider = () => {
  const { locale } = useIntl();

  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      autoplay={{
        disableOnInteraction: false,
        reverseDirection: isArabic(locale),
      }}
      className="rewards-slider"
      centeredSlides={true}
      loop={true}
      pagination={{
        clickable: true,
      }}
      roundLengths={true}
      slidesPerView={1.2}
      spaceBetween={20}
      breakpoints={{
        768: {
          loop: false,
          slidesPerView: 1.5,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1440: {
          slidesPerView: 2.6,
        },
      }}
    >
      <SwiperSlide className={isArabic(locale) ? 'arabic' : ''}>
        <div className="slider-card">
          <RewardsIcon1 className="slider-card__icon" />
          <CardTitle className="slider-card__title">
            {translate('getPaid.slider.title1')}
          </CardTitle>
          <CardText>
            {translate('getPaid.slider.description1')}
          </CardText>
        </div>
      </SwiperSlide>
      <SwiperSlide className={isArabic(locale) ? 'arabic' : ''}>
        <div className="slider-card">
          <RewardsIcon2 className="slider-card__icon" />
          <CardTitle className="slider-card__title">
            {translate('getPaid.slider.title2')}
          </CardTitle>
          <CardText>
            {translate('getPaid.slider.description2')}
          </CardText>
        </div>
      </SwiperSlide>
      <SwiperSlide className={isArabic(locale) ? 'arabic' : ''}>
        <div className="slider-card">
          <RewardsIcon3 className="slider-card__icon" />
          <CardTitle className="slider-card__title">
            {translate('getPaid.slider.title3')}
          </CardTitle>
          <CardText>
            {translate('getPaid.slider.description3')}
          </CardText>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default RewardsSlider;
