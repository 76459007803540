import './CardText.scss';

const CardText = (props) => {
  return (
    <p className="card-text-container">
      { props.children }
    </p>
  );
}

export default CardText;
