import './Subtitle.scss';

const Subtitle = ({ text }) => {
  return (
    <p className="default-subtitle">
      { text }
    </p>
  );
}

export default Subtitle;
