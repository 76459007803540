import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from 'i18n/constants';
import messages from 'i18n/translations';

const I18nProvider = ({ children, locale = LOCALES.en }) => (
  <IntlProvider
    locale={locale}
    messages={messages[locale]}
    textComponent={Fragment}
  >
    { children }
  </IntlProvider>
);

export default I18nProvider;