import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Subtitle, Text } from 'components'
import { isArabic, translate } from 'i18n';
import './SecurityPage.scss';

const SecurityPage = () => {
  const { locale } = useIntl();
  const videoRef = useRef(null);
  const mobileVideoRef = useRef(null);

  useEffect(() => {
    videoRef.current.load();
    mobileVideoRef.current.load();
  }, [locale])

  return (
    <>
      <section className="security-intro">
        <video
          className="security-intro__video"
          id="security-video"
          autoPlay
          loop
          muted
          ref={videoRef}
        >
          <source
            src={require(`assets/videos/${isArabic(locale) ? 'security-arabic' : 'security'}.mp4`)}
            type="video/mp4"
          />
        </video>
        <video
          className="security-intro__video"
          id="security-video-mobile"
          autoPlay
          loop
          playsInline
          muted
          ref={mobileVideoRef}
        >
          <source
            src={require(`assets/videos/${isArabic(locale) ? 'security-mobile-arabic' : 'security-mobile'}.mp4`)}
            type="video/mp4"
          />
        </video>
        <section className="security-intro__content">
          <Subtitle text={translate('security.intro.subtitle1')} />
          <Subtitle text={translate('security.intro.subtitle2')} />
        </section>
      </section>
      <section className="security-content">
        <div className="security-content__wrapper">
          <div className="left-wrapper wrapper">
            <Subtitle text={translate('security.content.subtitle')} />
          </div>
          <div className="right-wrapper wrapper">
            <Text text={translate('security.content.paragraph1')} />
          </div>
          <div className="left-wrapper wrapper">
            <Text text={translate('security.content.paragraph2')} />
          </div>
          <div className="right-wrapper wrapper">
            <Text text={translate('security.content.paragraph3')} />
          </div>
        </div>
      </section>
    </>
  );
}

export default SecurityPage;
