import { useIntl } from 'react-intl';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isArabic } from 'i18n';
import 'styles/components/Screenshots.scss';

function Screenshots() {
  const { locale } = useIntl();

  return (
    <section className="screenshots">
      <div className="screenshots__list">
        <div className="screenshots__list-item">
          <img
            src={require(`assets/images/screen1${isArabic(locale) ? 'ar' : ''}.png`)}
            alt="screen1"
          />
        </div>
        <div className="screenshots__list-item">
          <img
            src={require(`assets/images/screen2${isArabic(locale) ? 'ar' : ''}.png`)}
            alt="screen2"
          />
        </div>
        <div className="screenshots__list-item">
          <img
            src={require(`assets/images/screen3${isArabic(locale) ? 'ar' : ''}.png`)}
            alt="screen3"
          />
        </div>
        <div className="screenshots__list-item">
          <img
            src={require(`assets/images/screen4${isArabic(locale) ? 'ar' : ''}.png`)}
            alt="screen4"
          />
        </div>
      </div>
      <div className="screenshots__slider">
        <Swiper
          modules={[Pagination]}
          className="screenshots-slider"
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
          breakpoints={{
            600: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide>
            <div className="screenshots__list-item">
              <img
                src={require(`assets/images/screen1${isArabic(locale) ? 'ar' : ''}.png`)}
                border="0"
                alt="screen1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="screenshots__list-item">
              <img
                src={require(`assets/images/screen2${isArabic(locale) ? 'ar' : ''}.png`)}
                alt="screen2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="screenshots__list-item">
              <img
                src={require(`assets/images/screen3${isArabic(locale) ? 'ar' : ''}.png`)}
                alt="screen3"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="screenshots__list-item">
              <img
                src={require(`assets/images/screen4${isArabic(locale) ? 'ar' : ''}.png`)}
                alt="screen4"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default Screenshots;
