const textStyle = {
  fontFamily: 'FK Grotesk Thin',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  textAlign: 'justify',
  lineHeight: '32px',
  zIndex: 15,
};

const LegalText = ({ children }) => (
  <p className="px-1 py-3 mt-4" style={textStyle}>
    {children}
  </p>
);

export default LegalText;

