const headerStyle = {
  fontFamily: "FK Grotesk Regular",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "32px",
  lineHeight: "32px",
  textAlign: "center",
  zIndex: 15,
};

const LegalH2 = ({ children }) => (
  <h2 className="mt-4" style={headerStyle}>
    {children}
  </h2>
);

export default LegalH2;
