import './Title.scss';

const Title = ({ text }) => {
  return (
    <h2 className="default-title">
      { text }
    </h2>
  );
}

export default Title;
