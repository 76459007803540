import { AdjustIcon, GiftIcon, LockIcon } from 'icons'
import { translate } from 'i18n';

import 'styles/components/Features.scss';

function Features() {
  return (
    <section className="features">
      <div className="features__container">
        <div className="features__description">
          <h6 className="features__description--title">
            {translate('landing.features.title')}
          </h6>
          <p className="features__description--paragraph">
            {translate('landing.features.paragraph1')}
          </p>
          <p className="features__description--paragraph">
            {translate('landing.features.paragraph2')}
          </p>
        </div>
        <div className="features__bullets">
          <div className="features__bullets-item">
            <GiftIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.getSurvey')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.getSurveyDescription')}
            </p>
          </div>
          <div className="features__bullets-item features__bullets-item-second">
            <AdjustIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.getFitness')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.getFitnessDescription')}
            </p>
          </div>
          <div className="features__bullets-item features__bullets-item-second">
            <LockIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.getPlayTime')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.getPlayTimeDescription')}
            </p>
          </div>
          <div className="features__bullets-item features__bullets-item-second">
            <GiftIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.getPayTime')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.getPayTimeDescription')}
            </p>
          </div>
          <div className="features__bullets-item features__bullets-item-second">
            <AdjustIcon className="features__bullets-item--icon" />
            <h5 className="features__bullets-item--title">
              {translate('landing.features.getLevelUp')}
            </h5>
            <p className="features__bullets-item--description">
              {translate('landing.features.getLevelUpDescription1')}
            </p>
            <p className="features__bullets-item--description">
              {translate('landing.features.getLevelUpDescription2')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
