import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Title } from 'components';
import { AppleIcon, GooglePlayIcon } from 'icons';
import { APP_STORE_LINK, PLAY_MARKET_LINK } from 'constants';
import { isArabic, translate } from 'i18n';
import 'styles/components/Intro.scss';

function Intro() {
  const { locale } = useIntl();
  const videoRef = useRef(null);
  const mobileVideoRef = useRef(null);

  useEffect(() => {
    videoRef.current.load();
    mobileVideoRef.current.load();
  }, [locale])

  return (
    <section className="intro">
      <video
        className="intro__video"
        id="bg-video"
        autoPlay
        loop
        muted
        ref={videoRef}
      >
        <source
          src={require(`assets/videos/${isArabic(locale) ? 'bg-arabic' : 'bg'}.mp4`)}
          type="video/mp4"
        />
      </video>
      <video
        className="intro__video"
        id="bg-video-mobile"
        autoPlay
        loop
        playsInline
        muted
        ref={mobileVideoRef}
      >
        <source
          src={require(`assets/videos/${isArabic(locale) ? 'bg-mobile-arabic' : 'bg-mobile'}.mp4`)}
          type="video/mp4"
        />
      </video>
      <div className="intro__content">
        <div className="intro__title">
          <Title
            text={translate('landing.intro.title')}
          />
        </div>
        <div className="intro__qr">
          <div className="intro__qr-container">
            <img src={require(`assets/images/qrwallacewallet.jpg`)} alt="qr" />
            <div className="intro__qr-label">
              <span>{translate('landing.intro.download')}</span>
            </div>
          </div>
        </div>
        <div className="intro__links">
          <a className="intro__links-item" href={APP_STORE_LINK}>
            <div className="intro__links-container">
              <AppleIcon />
            </div>
            <span>App Store</span>
          </a>
          <a className="intro__links-item" href={PLAY_MARKET_LINK}>
            <div className="intro__links-container">
              <GooglePlayIcon />
            </div>
            <span>Google Play</span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Intro;
