import {
  Features,
  Intro,
  MoreFeatures,
  Screenshots
} from 'components';
import { CookiesBox } from 'components'
import { useState } from 'react';

function Landing() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false)
  return (
    <>
      <Intro />
      <Features />
      <Screenshots />
      <MoreFeatures />
      {!cookiesAccepted && <CookiesBox handleAcceptCookies={() => setCookiesAccepted(true)}/>}
    </>
  );
}

export default Landing;
