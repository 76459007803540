import { useState } from 'react';
import { saveEmail as saveEmailFirestore } from 'services/firestore';

const useSaveEmail = () => {
  const [result, setResult] = useState();

  const saveEmail = async email => {
    try {
      await saveEmailFirestore(email);
      setResult("SUCCESS");
    } catch (error) {
      setResult("ERROR");
    } 
  }

  return { saveEmail, result };
}

export default useSaveEmail;
