const textStyle = {
  fontFamily: 'FK Grotesk Thin',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  zIndex: 15,
};

const LegalListItem = ({ children }) => (
  <li className="d-flex mt-2" style={textStyle}>
    &bull; {children}
  </li>
);

export default LegalListItem;
