const headerStyle = {
  fontFamily: 'FK Grotesk Thin',
  fontStyle: 'italic',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '32px',
  textAlign: 'center',
  zIndex: 15,
};

const LegalH3 = ({ children }) => (
  <h3 className="p-3 mt-4" style={headerStyle}>
    {children}
  </h3>
);

export default LegalH3;
