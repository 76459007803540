export const APP_STORE_LINK = 'https://apps.apple.com/es/app/wallace-wallet-earn-rewards/id1638890575';
export const PLAY_MARKET_LINK = 'https://play.google.com/store/apps/details?id=com.geodb.wallace';

export const INSTAGRAM_HREF = 'https://www.instagram.com/wallacecryptowallet/';
export const TELEGRAM_HREF = 'https://t.me/WallaceWallet';
export const TWITTER_HREF = 'https://twitter.com/wallace_wallet';

export const INSTAGRAM_HREF_ARABIC = 'https://instagram.com/mena_wallace?igshid=YmMyMTA2M2Y=';
export const TELEGRAM_HREF_ARABIC = 'https://t.me/WallaceWallet';
export const TWITTER_HREF_ARABIC = 'https://twitter.com/wallace_MENA';
