import './CardTitle.scss';

const CardTitle = (props) => {
  return (
    <p className="card-title-container">
      { props.children }
    </p>
  );
}

export default CardTitle;
