const headerStyle = {
  fontFamily: 'FK Grotesk Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '48px',
  lineHeight: '64px',
  textAlign: 'center',
  zIndex: 15,
};

const LegalH1 = ({ children }) => (
  <h1 className="p-5 mt-5" style={headerStyle}>
    {children}
  </h1>
);

export default LegalH1;
