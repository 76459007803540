import { useIntl } from 'react-intl';
import Accordion from 'react-bootstrap/Accordion';
import { Subtitle } from 'components';
import { isArabic, translate } from 'i18n';
import Glossary from './Glossary';
import './Academy.scss';

const Academy = () => {
  const { locale } = useIntl();

  return (
    <section className="academy-content">
      <Subtitle className="academy-subtitle" text={translate('academy.title')} />
      <Accordion className="academy-accordion">
        <Accordion.Item className="academy-accordion__item" eventKey="0">
          <Accordion.Header className="academy-accordion__header">
            <span>{translate('academy.accordion.title1')}</span>
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <span className="span-block">{translate('academy.accordion.content1.span1')}</span>
            <span className="span-block">{translate('academy.accordion.content1.span2')}</span>
            <span className="span-block">{translate('academy.accordion.content1.span3')}</span>
            <br />
            <p>{translate('academy.accordion.content1.p1')}</p>
            <br />
            <ol>
              <li>{translate('academy.accordion.content1.ol1.li1')}</li>
              <li>{translate('academy.accordion.content1.ol1.li2')}</li>
              <li>{translate('academy.accordion.content1.ol1.li3')}</li>
            </ol>
            <br />
            <span>{translate('academy.accordion.content1.span4')}</span>
            <ul>
              <li>{translate('academy.accordion.content1.ul1.li1')}</li>
              <li>{translate('academy.accordion.content1.ul1.li2')}</li>
              <li>{translate('academy.accordion.content1.ul1.li3')}</li>
              <li>{translate('academy.accordion.content1.ul1.li4')}</li>
              <li>{translate('academy.accordion.content1.ul1.li5')}</li>
            </ul>
            <br />
            <p>
              <b>{translate('academy.accordion.content1.p2.b')}</b>
            </p>
            <p>
              <span className="span-block">{translate('academy.accordion.content1.p3.s1')}</span>
              <span className="span-block">{translate('academy.accordion.content1.p3.s2')}</span>
              <span className="span-block">{translate('academy.accordion.content1.p3.s3')}</span>
              <span className="span-block">{translate('academy.accordion.content1.p3.s4')}</span>
              {isArabic(locale) && <span className="span-block">تُتيح تقنية البلوك تشين كذلك ترميز أصول مختلفة في العالم الافتراضي والواقعي، بما في ذلك ترميز البيانات والأسهم والذهب والتراخيص وحقوق الملكية والفن الرقمي والموسيقى وغير ذلك. تتمثّل عملية الترميز في تحويل أصل أو حقوق الملكية الخاصة بالأصل إلى وحدة فريدة تُعرف باسم الرمز المميَّز (أو التوكن). وتُتيح تقنية البلوك تشين كذلك إمكانية ترميز البيانات التي تساعد الشركات في الحصول على بيانات أكثر موثوقية لتستخدمها في عمليات التسويق والحملات الإعلانية وفي اتّخاذ القرارات وتنفيذ الإستراتيجيات وغير ذلك، مع السماح في الوقت نفسه للمُستخدِمين الذين يُنشئون هذه البيانات بتحويلها إلى نقود يُمكن قبضها ومشاركتها.</span>}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="1">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title2')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <ul>
              <li>{translate('academy.accordion.content2.ul1.li1')}</li>
              <li>{translate('academy.accordion.content2.ul1.li2')}</li>
              <li>{translate('academy.accordion.content2.ul1.li3')}</li>
              <li>{translate('academy.accordion.content2.ul1.li4')}</li>
              <li>{translate('academy.accordion.content2.ul1.li5')}</li>
              <li>{translate('academy.accordion.content2.ul1.li6')}</li>
              <li>{translate('academy.accordion.content2.ul1.li7')}</li>
            </ul>
            <br />
            <ol>
              <li>{translate('academy.accordion.content2.ol1.li1')}</li>
              <li>{translate('academy.accordion.content2.ol1.li2')}</li>
              <li>{translate('academy.accordion.content2.ol1.li3')}</li>
              <li>{translate('academy.accordion.content2.ol1.li4')}</li>
              <li>{translate('academy.accordion.content2.ol1.li5')}</li>
              <li>{translate('academy.accordion.content2.ol1.li6')}</li>
            </ol>
            <br />
            <ul>
              <li>{translate('academy.accordion.content2.ul2.li1')}</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="2">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title3')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <p>{translate('academy.accordion.content3.p')}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="3">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title4')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <p><b>{translate('academy.accordion.content4.ul1.p1')}</b></p>
            <br />
            <ul>
              <li>{translate('academy.accordion.content4.ul1.ul')}</li>
              <br />
              <ul>
                <li className="no-list"><b>{translate('academy.accordion.content4.ul1.ul1.li1')}</b></li>
                <br />
                <li>{translate('academy.accordion.content4.ul1.ul1.li2')}</li>
                <li>{translate('academy.accordion.content4.ul1.ul1.li3')}</li>
                <li>{translate('academy.accordion.content4.ul1.ul1.li4')}</li>
              </ul>
            </ul>
            <br />
            <p><b>{translate('academy.accordion.content4.p1')}</b></p>
            <br />
            <ul>
              <li>{translate('academy.accordion.content4.ul2.li1')}</li>
              <li>{translate('academy.accordion.content4.ul2.li2')}</li>
              <li>{translate('academy.accordion.content4.ul2.li3')}</li>
              <li>{translate('academy.accordion.content4.ul2.li4')}</li>
              <li>{translate('academy.accordion.content4.ul2.li5')}</li>
            </ul>
            <br />
            <p><b>{translate('academy.accordion.content4.p2')}</b></p>
            <br />
            <ul>
              <li><b>{translate('academy.accordion.content4.ul3.li1')}</b></li>
              <br />
              <p>{translate('academy.accordion.content4.ul3.p1')}</p>
              <br />
              <ul>
                <li className="no-list"><b>{translate('academy.accordion.content4.ul3.ul1.li1')}</b></li>
                <br />
                <li>{translate('academy.accordion.content4.ul3.ul1.li2')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul1.li3')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul1.li4')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul1.li5')}</li>
              </ul>
              <br />
              <li><b>{translate('academy.accordion.content4.ul3.li2')}</b></li>
              <br />
              <p>{translate('academy.accordion.content4.ul3.p2')}</p>
              <br />
              <ul>
                <li className="no-list"><b>{translate('academy.accordion.content4.ul3.ul2.li1')}</b></li>
                <br />
                <li>{translate('academy.accordion.content4.ul3.ul2.li2')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul2.li3')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul2.li4')}</li>
              </ul>
              <br />
              <li><b>{translate('academy.accordion.content4.ul3.li3')}</b></li>
              <br />
              <p>
                <span className="span-block">{translate('academy.accordion.content4.ul3.p3.span1')}</span>
                <span className="span-block">{translate('academy.accordion.content4.ul3.p3.span2')}</span>
              </p>
              <br />
              <ul>
                <li className="no-list"><b>{translate('academy.accordion.content4.ul3.ul3.li1')}</b></li>
                <br />
                <li>{translate('academy.accordion.content4.ul3.ul3.li2')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul3.li3')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul3.li4')}</li>
                <li>{translate('academy.accordion.content4.ul3.ul3.li5')}</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="4">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title5')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <p>{translate('academy.accordion.content5.p1')}</p>
            <br />
            <ul>
              <li><b>{translate('academy.accordion.content5.ul.li1')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content5.ul.span1')}</span>
              <span className="span-block">{translate('academy.accordion.content5.ul.span2')}</span>
              <ul>
                <li>{translate('academy.accordion.content5.ul.ul1.li1')}</li>
                <li>{translate('academy.accordion.content5.ul.ul1.li2')}</li>
                <li>{translate('academy.accordion.content5.ul.ul1.li3')}</li>
                <li>{translate('academy.accordion.content5.ul.ul1.li4')}</li>
              </ul>
              <span className="span-block">{translate('academy.accordion.content5.ul.span3')}</span>
              <br />
              <li><b>{translate('academy.accordion.content5.ul.li2')}</b></li>
              <br />
              <p><b>{translate('academy.accordion.content5.p2')}</b></p>
              <br />
              <span className="span-block">{translate('academy.accordion.content5.ul.span4')}</span>
              <span className="span-block">{translate('academy.accordion.content5.ul.span5')}</span>
              <span className="span-block">{translate('academy.accordion.content5.ul.span6')}</span>
              <span className="span-block">{translate('academy.accordion.content5.ul.span7')}</span>
              <br />
              <p><b>{translate('academy.accordion.content5.p3')}</b></p>
              <br />
              <ul>
                <li>{translate('academy.accordion.content5.ul.ul2.li1')}</li>
                <li>{translate('academy.accordion.content5.ul.ul2.li2')}</li>
                <li>{translate('academy.accordion.content5.ul.ul2.li3')}</li>
                <li>{translate('academy.accordion.content5.ul.ul2.li4')}</li>
                <li>{translate('academy.accordion.content5.ul.ul2.li5')}</li>
                <li>{translate('academy.accordion.content5.ul.ul2.li6')}</li>
              </ul>
              <br />
              <p><b>{translate('academy.accordion.content5.p4')}</b></p>
              <br />
              <ul>
                <li>{translate('academy.accordion.content5.ul.ul3.li1')}</li>
                <li>{translate('academy.accordion.content5.ul.ul3.li2')}</li>
                <li>{translate('academy.accordion.content5.ul.ul3.li3')}</li>
                <li>{translate('academy.accordion.content5.ul.ul3.li4')}</li>
                <li>{translate('academy.accordion.content5.ul.ul3.li5')}</li>
                <li>{translate('academy.accordion.content5.ul.ul3.li6')}</li>
                <li>{translate('academy.accordion.content5.ul.ul3.li7')}</li>
              </ul>
              <br />
              <p><b>{translate('academy.accordion.content5.p5')}</b></p>
              <br />
              <ul>
                <li>{translate('academy.accordion.content5.ul.ul4.li1')}</li>
                <li>{translate('academy.accordion.content5.ul.ul4.li2')}</li>
                <li>{translate('academy.accordion.content5.ul.ul4.li3')}</li>
                <li>{translate('academy.accordion.content5.ul.ul4.li4')}</li>
                <li>{translate('academy.accordion.content5.ul.ul4.li5')}</li>
              </ul>
              <br />
              <li><b>{translate('academy.accordion.content5.ul.li3')}</b></li>
              <br />
              <li><b>{translate('academy.accordion.content5.ul.li4')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content5.ul.span8')}</span>
              <ul>
                <li>{translate('academy.accordion.content5.ul.ul5.li1')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li2')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li3')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li4')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li5')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li6')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li7')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li8')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li9')}</li>
                <li>{translate('academy.accordion.content5.ul.ul5.li10')}</li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="5">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title6')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <ul>
              <li><b>{translate('academy.accordion.content6.ul.li1')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content6.ul.span1')}</span>
              <span className="span-block">{translate('academy.accordion.content6.ul.span2')}</span>
              <span className="span-block">{translate('academy.accordion.content6.ul.span3')}</span>
              <br />
              <li><b>{translate('academy.accordion.content6.ul.li2')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content6.ul.span4')}</span>
              <span className="span-block">{translate('academy.accordion.content6.ul.span5')}</span>
              <br />
              <li><b>{translate('academy.accordion.content6.ul.li3')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content6.ul.span6')}</span>
              <span className="span-block">{translate('academy.accordion.content6.ul.span7')}</span>
              <span className="span-block">{translate('academy.accordion.content6.ul.span8')}</span>
              <br />
              <li><b>{translate('academy.accordion.content6.ul.li4')}</b></li>
              <br />
              <ol>
                <li>{translate('academy.accordion.content6.ul.ol1.li1')}</li>
                <li>{translate('academy.accordion.content6.ul.ol1.li2')}</li>
                <li>{translate('academy.accordion.content6.ul.ol1.li3')}</li>
                <li>{translate('academy.accordion.content6.ul.ol1.li4')}</li>
              </ol>
              <br />
              <li><b>{translate('academy.accordion.content6.ul.li5')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content6.ul.span9')}</span>
              <br />
              <ol>
                <li>{translate('academy.accordion.content6.ul.ol2.li1')}</li>
                <li>{translate('academy.accordion.content6.ul.ol2.li2')}</li>
              </ol>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="6">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title7')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <ul>
              <li><b>{translate('academy.accordion.content7.ul.li1')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span1')}</span>
              <br />
              <li><b>{translate('academy.accordion.content7.ul.li2')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span2')}</span>
              <br />
              <li><b>{translate('academy.accordion.content7.ul.li3')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span3')}</span>
              <br />
              <li><b>{translate('academy.accordion.content7.ul.li4')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span4')}</span>
              <br />
              <li><b>{translate('academy.accordion.content7.ul.li5')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span5')}</span>
              <br />
              <li><b>{translate('academy.accordion.content7.ul.li6')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span6')}</span>
              <br />
              <li><b>{translate('academy.accordion.content7.ul.li7')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span7')}</span>
              <br />
              <li><b>{translate('academy.accordion.content7.ul.li8')}</b></li>
              <br />
              <span className="span-block">{translate('academy.accordion.content7.ul.span8')}</span>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="7">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title8')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <ul>
              <li>{translate('academy.accordion.content8.ul1.li1')}</li>
              <br />
              <li>{translate('academy.accordion.content8.ul1.li2')}</li>
              <br />
              <li>{translate('academy.accordion.content8.ul1.li3')}</li>
              <br />
              <li>{translate('academy.accordion.content8.ul1.li4')}</li>
              <br />
              <li>{translate('academy.accordion.content8.ul1.li5')}</li>
              <br />
              <li>{translate('academy.accordion.content8.ul1.li6')}</li>
              {isArabic(locale) && <>
                <br />
                <li>يمكن مشاركة المفاتيح العامة مع أي شخص، إذ يمكن استخدامها لإرسال العملة المشفَّرة إليك. يمكنك مشاركة مفاتيحك العامة بقدر ما ترغب. يتم الحصول على المفاتيح العامة بشكل مشفّر من المفاتيح الخاصة لعملاتك المعدنية، إلا أنها تستغرق مليارات السنوات لفك الرمز حتى مع استخدام أقوى الحواسيب الخارقة (تحديدًا 204 tresvigintillion).</li>
              </>}
            </ul>
            <br />
            <p><b>{translate('academy.accordion.content8.p1')}</b></p>
            <br />
            <ul>
              <li>{translate('academy.accordion.content8.ul2.li1')}</li>
              <br />
              <li>{translate('academy.accordion.content8.ul2.li2')}</li>
              <br />
              <ol>
                <li>{translate('academy.accordion.content8.ol1.li1')}</li>
                <li>{translate('academy.accordion.content8.ol1.li2')}</li>
                <li>{translate('academy.accordion.content8.ol1.li3')}</li>
              </ol>
            </ul>            
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="8">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title9')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <p><b>{translate('academy.accordion.content9.p1')}</b></p>
            <br />
            <ul>
              <li>{translate('academy.accordion.content9.ul1.li1')}</li>
              <li>{translate('academy.accordion.content9.ul1.li2')}</li>
              <li>{translate('academy.accordion.content9.ul1.li3')}</li>
              <li>{translate('academy.accordion.content9.ul1.li4')}</li>
            </ul>
            <br />
            <br />
            <p><b>{translate('academy.accordion.content9.p2')}</b></p>
            <br />
            <ul>
              <li><b>{translate('academy.accordion.content9.ul2.li1')}</b></li>
              <br />
              <p>{translate('academy.accordion.content9.ul2.p1')}</p>
              <br />
              <li><b>{translate('academy.accordion.content9.ul2.li2')}</b></li>
              <br />
              <p>{translate('academy.accordion.content9.ul2.p2')}</p>
              <br />
              <li><b>{translate('academy.accordion.content9.ul2.li3')}</b></li>
              <br />
              <p>{translate('academy.accordion.content9.ul2.p3')}</p>
              <br />
              <li><b>{translate('academy.accordion.content9.ul2.li4')}</b></li>
              <br />
              <p>{translate('academy.accordion.content9.ul2.p4')}</p>
              <br />
              <li><b>{translate('academy.accordion.content9.ul2.li5')}</b></li>
              <br />
              <p>{translate('academy.accordion.content9.ul2.p5')}</p>
              <br />
              <li><b>{translate('academy.accordion.content9.ul2.li6')}</b></li>
              <br />
              <p>{translate('academy.accordion.content9.ul2.p6')}</p>
              <br />
              <li><b>{translate('academy.accordion.content9.ul2.li7')}</b></li>
              <br />
              <p>{translate('academy.accordion.content9.ul2.p7')}</p>
              <br />
            </ul>
            <br />
            <p><b>{translate('academy.accordion.content9.p3')}</b></p>
            <br />
            <p>{translate('academy.accordion.content9.p4')}</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="academy-accordion__item" eventKey="9">
          <Accordion.Header className="academy-accordion__header">
            {translate('academy.accordion.title10')}
            <i className="academy-accordion__header-icon" />
          </Accordion.Header>
          <Accordion.Body className="academy-accordion__body">
            <Glossary />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </section>
  );
}

export default Academy;
