import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  AppleIcon,
  ButtonArrow,
  ButtonArrowArabic,
  GooglePlayIcon,
  InstagramIcon,
  TelegramIcon,
  TwitterIcon,
} from 'icons'
import {
  APP_STORE_LINK,
  INSTAGRAM_HREF,
  INSTAGRAM_HREF_ARABIC,
  PLAY_MARKET_LINK,
  TELEGRAM_HREF,
  TELEGRAM_HREF_ARABIC,
  TWITTER_HREF,
  TWITTER_HREF_ARABIC,
} from 'constants';
import { isArabic, translate } from 'i18n';
import useSaveEmail from 'hooks/useSaveEmail';
import 'styles/components/Footer.scss';

function Footer() {
  const intl = useIntl();
  const [checkbox, setCheckbox] = useState(false);
  const [email, setEmail] = useState('');

  const { saveEmail, result } = useSaveEmail();

  const onFormSubmit = (e) => {
    e.preventDefault();
    setCheckbox(false);
    saveEmail(email);
    setEmail('');
  }

  const onInputChange = (event) => {
    setEmail(event.target.value);
  }

  return (
    <section className="footer">
      <div className="footer__container">
        <div className="footer__title">
          <p>{translate('footer.title')}</p>
        </div>
        <div className="footer__description">
          <h3>{translate('footer.subtitle')}</h3>
          <div className="footer__links">
            <div className="footer__links-container">
              <a className="footer__links-item" href={APP_STORE_LINK}>
                <div className="footer__icon">
                  <AppleIcon />
                </div>
                <span className="footer__icon-name">App Store</span>
              </a>
              <a className="footer__links-item" href={PLAY_MARKET_LINK}>
                <div className="footer__icon">
                  <GooglePlayIcon />
                </div>
                <span className="footer__icon-name">Google Play</span>
              </a>
            </div>
          </div>
          <p className="footer__form-title">
            {translate('footer.formTitle')}
          </p>
          <form className="footer__form-wrapper" onSubmit={onFormSubmit}>
            <div className="footer__input-wrapper">
              <input
                className="footer__form-input"
                placeholder={intl.formatMessage({ id: 'footer.inputPlaceholder' })}
                type="email"
                required
                value={email}
                onChange={onInputChange}
              />
              <button className="footer__form-button" type="submit">
                {isArabic(intl.locale) ? <ButtonArrowArabic /> : <ButtonArrow />}
              </button>
            </div>
            <div className="footer__checkbox-wrapper">
              <input
                className="footer__checkbox"
                type="checkbox"
                checked={checkbox}
                value={checkbox}
                required
                onChange={e => setCheckbox(e.target.value)}
              />
              <label>
                {translate('footer.privacyP1')} <Link className="footer__link" to='/privacy' target="_blank" rel="noopener noreferrer"> {translate('footer.privacyP2')}</Link>
              </label>
            </div>
          </form>
          {!!result && result === "ERROR" && <div className="footer__form-sent">
            {translate('footer.formError')}
          </div>}
          {!!result && result === "SUCCESS" && <div className="footer__form-sent">
            {translate('footer.formSuccess')}
          </div>}
        </div>
      </div>
      <div className="footer__socials">
        <div className="footer__socials-copyright">
          <span>{translate('footer.copyright')}</span>
        </div>
        <div className="footer__socials-icons">
          <a href={isArabic(intl.locale) ? TWITTER_HREF_ARABIC :TWITTER_HREF} rel="noreferrer" target="_blank">
            <TwitterIcon />
          </a>
          <a href={isArabic(intl.locale) ? INSTAGRAM_HREF_ARABIC : INSTAGRAM_HREF} rel="noreferrer" target="_blank">
            <InstagramIcon />
          </a>
          <a href={isArabic(intl.locale) ? TELEGRAM_HREF_ARABIC : TELEGRAM_HREF} rel="noreferrer" target="_blank">
            <TelegramIcon />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Footer;