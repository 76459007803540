import { useIntl } from 'react-intl';
import {
  Subtitle,
  Text,
  Title,
  RewardsSlider,
} from 'components'
import { isArabic, translate } from 'i18n';
import './GetPaidPage.scss';

function GetPaidPage() {
  const { locale } = useIntl();

  return (
    <>
      <div className="getpaid">
        <section className="getpaid-intro">
          <div className="getpaid-intro__text">
            <Title
              text={translate('getPaid.intro.title')}
            />
            <Text
              text={translate('getPaid.intro.description')}
            />
          </div>
          <div className="getpaid-intro__image">
            <img
              src={require(`assets/images/phones${isArabic(locale) ? '-ar' : ''}.png`)}
              alt="phones"
            />
          </div>
        </section>
      </div>
      <section className="getpaid-slider">
        <div className="getpaid-slider__title">
          <Subtitle text={translate('getPaid.slider.subtitle')} />
        </div>
        <div className="getpaid-slider__container">
          <RewardsSlider />
        </div>
      </section>
    </>
  );
}

export default GetPaidPage;
