import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { DARK_MODE_PAGES } from 'components/Header/constants';
import { isArabic, LOCALES, translate } from 'i18n';
import './Header.scss';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-WW8QBQV'
}
 
TagManager.initialize(tagManagerArgs)

function Header(props) {
  const { locale } = useIntl();
  const location = useLocation();

  const isDarkMode = DARK_MODE_PAGES.includes(location.pathname);

  return (
    <Navbar className={`header ${isDarkMode ? 'header-dark' : ''}`} id="header" collapseOnSelect expand="md">
      <LinkContainer to="/">
        <Navbar.Brand>
          <img className="header-logo" src={require('../../assets/images/logo.png')} alt="logo" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Nav className="header-nav">
          
          
          
          {/*<LinkContainer to="/tutorial">
            <Nav.Link className="header-nav__item" disabled={true}>
              {translate('header.tutorial')}
            </Nav.Link>
          </LinkContainer>*/}
          <NavDropdown title={isArabic(locale) ? 'عربي' : 'English'}>
            <NavDropdown.Item
              as="button"
              className="header-nav__item"
              onClick={() => props.setLocale(LOCALES.en)}
            >
              English
            </NavDropdown.Item>
            <NavDropdown.Item
              as="button"
              className="header-nav__item"
              onClick={() => props.setLocale(LOCALES.ar)}
            >
              عربي
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;