import {
  Subtitle,
  Text,
  Title,
} from 'components'
import { translate } from 'i18n';
import './MultiChainPage.scss';

const MultiChainPage = () => {
  return (
    <>
      <section className="multi-intro">
        <div className="multi-intro__content">
          <Title text={translate('multiChain.intro.title')} />
          <Text text={translate('multiChain.intro.description')} />
        </div>
      </section>
      <section className="multi-text">
        <div className="multi-text__content">
          <div className="multi-text__content-common">
            <div className="multi-text__content-title">
              <Subtitle text={translate('multiChain.content.subtitle')} />
            </div>
            <div className="multi-text__content-figures">
              <img src={require('assets/images/figures.png')} alt="figures" />
            </div>
          </div>
          <div className="multi-text__content-description">
            <Text text={translate('multiChain.content.paragraph1')} />
            <img
              className="multi-text__content-image"
              src={require('assets/images/multi-figure1.png')}
              alt="multi-figure1"
            />
            <Text text={translate('multiChain.content.paragraph2')} />
            <img
              className="multi-text__content-image"
              src={require('assets/images/multi-figure2.png')}
              alt="multi-figure2"
            />
            <Text text={translate('multiChain.content.paragraph3')} />
            <img
              className="multi-text__content-image"
              src={require('assets/images/multi-figure3.png')}
              alt="multi-figure3"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default MultiChainPage;
