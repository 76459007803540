import React from 'react';
import { Container, Row } from 'react-bootstrap';
import {
  LegalColLeft,
  LegalColRight,
  LegalH1,
  LegalH2,
  LegalH3,
  LegalListItem,
  LegalText,
} from 'components/legal-layout';
import 'styles/pages/Privacy.scss';

const headerStyle = {
  fontFamily: 'FK Grotesk Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '32px',
  textAlign: 'center',
  zIndex: 15,
};

export default function Privacy() {
  return (
    <>
      <Container className="container-xl mb-5">
        <LegalH1>Privacy Policy</LegalH1>
        <div className="mt-4 p-3">
          <LegalH2>Region: United Kingdom</LegalH2>
          <LegalH2>Effective Date: January 2023</LegalH2>
          <ul className="p-0 pt-3">
            <LegalListItem>Who we are</LegalListItem>
            <LegalListItem>The information we process</LegalListItem>
            <LegalListItem>How we obtain information</LegalListItem>
            <LegalListItem>Your rights</LegalListItem>
            <LegalListItem>
              Changes to the way we use your information
            </LegalListItem>
            <LegalListItem>
              How we may use and share your information with other GeoDB group
              companies
            </LegalListItem>
            <LegalListItem>Sharing with third parties</LegalListItem>
            <LegalListItem>Transferring information overseas</LegalListItem>
            <LegalListItem>Marketing information</LegalListItem>
            <LegalListItem>Communications about your account</LegalListItem>
            <LegalListItem>Fraud prevention</LegalListItem>
            <LegalListItem>How long we keep your information</LegalListItem>
          </ul>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>1. Who we are</LegalH2>
          <LegalText>
            This data privacy, use and retention notice (the “Privacy Notice”)
            applies to all personal information collection and processing
            activities carried out by GeoDB Blockchain Limited. (together, “GeoDB”).
            GeoDB is a data controller in respect of personal information that
            we process in connection with our business (including the products
            and services that we provide through the GeoDB app). In this notice,
            references to “we”, “us” or “our” are references to GeoDB. Different
            GeoDB companies may control and process your data depending on where
            you are ordinarily resident. The GeoDB company that provides you
            with access to the GeoDB Service and your GeoDB Profile, as
            identified in the GeoDB General Terms of Service above ("GeoDB", or
            “we”, “our”, or “us”) is the GeoDB company directly responsible for
            handling your personal data.
          </LegalText>
          <LegalText>
            Our privacy email address for all GeoDB companies is
            hello@geodb.com. We respect individuals’ rights to privacy and to
            the protection of personal information. The purpose of this Privacy
            Notice is to explain how we collect and use personal information in
            connection with our business. “Personal information” means
            information about a living individual who can be identified from
            that information (either by itself or when it is combined with other
            information). We may update our Privacy Notice from time to time.
            When we do we will communicate any changes to you and publish the
            updated Privacy Notice on our website. We would encourage you to
            visit our website regularly to stay informed of the purposes for
            which we process your information and your rights to control how we
            process it. To find out about our use of cookies, please read of
            Cookie Policy.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>2. The information we process</LegalH2>
          <LegalText>
            We collect and process various categories of personal information at
            the start of, and for the duration of, your relationship with us. We
            will limit the collection and processing of information to
            information necessary to achieve one or more legitimate purposes as
            identified in this notice. Personal information may include:
          </LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>
              A. basic personal information, including name and address, date of
              birth and contact details;
            </LegalListItem>
            <LegalListItem>
              B. financial information, including account and transactional
              information and history;
            </LegalListItem>
            <LegalListItem>
              C. goods and services we provide to you;
            </LegalListItem>
            <LegalListItem>
              D. visual images and personal appearance (such as real-time
              biometric facial scans);
            </LegalListItem>
            <LegalListItem>
              E. online profile and social media information and activity, based
              on your interaction with us and our websites and applications,
              including for example your login information, Internet Protocol
              (IP) address, smart device information, location coordinates,
              online and mobile app security authentication, mobile phone
              network information, searches, site visits and spending patterns;
            </LegalListItem>
          </ul>

          <LegalText>
            We may also process certain special categories of information for
            specific and limited purposes, such as detecting and preventing
            financial crime or to make our services accessible to customers. We
            will only process special categories of information where we’ve
            obtained your explicit consent or are otherwise lawfully permitted
            to do so (and then only for the particular purposes and activities
            set out at Schedule B for which the information is provided). This
            may include biometric information, relating to the physical,
            physiological or behavioural characteristics of a person, including,
            for example, fingerprint or facial recognition or similar
            technologies to help us prevent fraud and money laundering.
          </LegalText>

          <LegalText>
            Where permitted by law, we may process information about criminal
            convictions or offences and alleged offences for specific and
            limited activities and purposes, such as to perform checks to
            prevent and detect crime and to comply with laws relating to money
            laundering, fraud, terrorist financing, bribery and corruption, and
            international sanctions. It may involve investigating and gathering
            intelligence on suspected financial crimes, fraud and threats and
            sharing data with taxation, law enforcement and regulatory bodies.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>3. How we obtain information</LegalH2>
          <LegalText>
            Your information is made up of all the financial and personal
            information we collect and hold about you/your business and the
            proprietors, officers and beneficial owners of that business and
            your transactions. It may include:
          </LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>A. information you give to us;</LegalListItem>
            <LegalListItem>
              B. information that we receive from third parties – including
              other GeoDB group companies, third parties who provide services to
              you or us, credit reference, fraud prevention or government
              agencies;
            </LegalListItem>
            <LegalListItem>
              C. and other financial institutions (where permitted by law);
            </LegalListItem>
            <LegalListItem>
              D. information that we learn about you through our relationship
              with you and the way you operate your accounts and/or services,
              such as the payments made to and from your accounts;
            </LegalListItem>
            <LegalListItem>
              E. information that we gather from the technology which you use to
              access our services (for example location data from your mobile
              phone, or an IP address or telephone number) and how you use it
              (for example pattern recognition);
            </LegalListItem>
            <LegalListItem>
              F. first party and third party cookies when you access our
              services, for more details please read our Cookie Policy;
            </LegalListItem>
            <LegalListItem>
              G. information that we gather from publicly available sources,
              such as the press, the electoral register, company registers and
              online search engines.
            </LegalListItem>
          </ul>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>4. Your rights</LegalH2>
          <LegalText>
            We want to make sure you are aware of your rights in relation to the
            personal information we process about you. We have described those
            rights and the circumstances in which they apply in the table at
            Schedule A. If you wish to exercise any of these rights, if you have
            any queries about how we use your personal information that are not
            answered here, or if you wish to complain to our Data Protection
            team, please contact us at hello@geodb.com.
          </LegalText>
          <LegalText>
            Please note that in some cases, if you do not agree to the way we
            process your information, it may not be possible for us to continue
            to operate your account and/or provide certain products and services
            to you through the GeoDB app. In the event our relationship with you
            terminated (for example, you choose to close your GeoDB Account),
            you may request the erasure of your personal data by contacting
            hello@geodb.com.
          </LegalText>
          <LegalText>
            Please note that GeoDB will only comply with such requests to the
            extent it is legally obligated to and depending on your account
            activity until that date, certain personal data may be maintained in
            accordance with anti-money laundering and counter-terrorist
            financing legislation to which GeoDB is subject.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>5. Changes to the way we use your information</LegalH2>
          <LegalText>
            From time to time we may change the way we use your information.
            Where we believe you may not reasonably expect such a change we will
            notify you and will allow a period of at least 30 days for you to
            raise any objections before the change is made. However, please note
            that in some cases, if you do not agree to such changes it may not
            be possible for us to continue to operate your account and/or
            provide certain products and services to you through the GeoDB app.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>
            6. How we may use and share your information with other GeoDB group
            companies
          </LegalH2>
          <LegalText>
            We will only use and share your information where it is necessary
            for us to lawfully carry out our business activities. Your
            information may be shared with and processed by other GeoDB group
            companies. We want to ensure that you fully understand how your
            information may be used. We have described the purposes for which
            your information may be used in detail in a table in Schedule B –
            Purposes of Processing.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>7. Sharing with third parties</LegalH2>
          <LegalText>
            We will not share your information with anyone outside of the GeoDB
            group except:
          </LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>A. where we have your permission;</LegalListItem>
            <LegalListItem>
              B. where required to provide your product(s) or service(s). This
              may include sharing your name with other GeoDB customers if you
              are already in the contact book stored on their device or when we
              are required to do so by the applicable law;
            </LegalListItem>
            <LegalListItem>
              C. where we are required by law and by law enforcement agencies,
              judicial bodies, government entities, tax authorities or
              regulatory bodies around the world;
            </LegalListItem>
            <LegalListItem>
              D. with other financial institutions and third parties where
              required by law to help recover funds that have entered your
              account as a result of a misdirected payment by such a third
              party;
            </LegalListItem>
            <LegalListItem>
              E. with third parties providing services to us, such as market
              analysis and benchmarking, correspondent banking, and agents and
              sub-contractors acting on our behalf, such as the companies which
              print our packing labels and provide your currency accounts;
            </LegalListItem>
            <LegalListItem>
              F. with other financial institutions to help trace funds where you
              are a victim of suspected financial crime and you have agreed for
              us to do so, or where we suspect funds have entered your account
              as a result of a financial crime;
            </LegalListItem>
            <LegalListItem>G. with debt collection agencies;</LegalListItem>
            <LegalListItem>
              H. with credit reference and fraud prevention agencies;
            </LegalListItem>
            <LegalListItem>
              I. with third-party guarantors or other companies that provide you
              with benefits or services (such as insurance cover) associated
              with your product or service;
            </LegalListItem>
            <LegalListItem>
              J. where required for a proposed sale, reorganisation, transfer,
              financial arrangement, asset disposal or other transaction
              relating to our business and/or assets held by our business;
            </LegalListItem>
            <LegalListItem>
              K. in anonymised form as part of statistics or other aggregated
              data shared with third parties; or
            </LegalListItem>
            <LegalListItem>
              L. where permitted by law, it is necessary for our legitimate
              interests or those of a third party, and it is not inconsistent
              with the purposes listed above.
            </LegalListItem>
          </ul>
          <LegalText>
            If you ask us to, we will share information with any third party
            that provides you with account information or payment services. If
            you ask a third-party provider to provide you with account
            information or payment services, you’re allowing that third party to
            access information relating to your account. We are not responsible
            for any such third party’s use of your account information, which
            will be governed by their agreement with you and any privacy
            statement they provide to you. In the event that any additional
            authorised users are added to your account, we may share information
            about the use of the account by any authorised user with all other
            authorised users. GeoDB will not share your information with third
            parties for their own marketing purposes without your permission.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>8. Transferring information overseas</LegalH2>
          <LegalText>
            We may transfer your information to or your information may be
            collected directly by organisations in other countries (including
            other GeoDB group companies) on the basis that anyone to whom we
            pass that information or who collects it directly protects it in the
            same way we would and in accordance with applicable laws. In the
            event that we transfer information to countries outside of the
            European Economic Area (which includes countries in the European
            Union as well as Iceland, Liechtenstein and Norway), we will only do
            so where:
          </LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>
              A. the European Commission has decided that the country or the
              organisation we are sharing your information with will protect
              your information adequately;
            </LegalListItem>
            <LegalListItem>
              B. the transfer has been authorised by the relevant data
              protection authority; and/or
            </LegalListItem>
            <LegalListItem>
              C. we have entered into a contract (deed of adherence) with the
              organisation with which we are sharing your information (based on
              the model clauses proposed by the European Commission) to ensure
              your information is adequately protected.
            </LegalListItem>
          </ul>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>9. Marketing information</LegalH2>
          <LegalText>
            Upon signing up to, and continued use of GeoDB’s services, you
            consent to hear from us regarding marketing updates. We will send
            you relevant marketing information (including details of other
            products or services provided by us or other GeoDB group companies
            which we believe may be of interest to you) by postal or electronic
            communication methods, including but not limited to email and SMS.
            If you change your mind about how you would like us to contact you,
            or you no longer wish to receive marketing material from us, you can
            simply unsubscribe from:
          </LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>
              1. Our marketing emails. By clicking “unsubscribe” in any emails
              we have sent.
            </LegalListItem>
            <LegalListItem>
              2. Our marketing text message. By texting “STOP” in any text
              messages we have sent.
            </LegalListItem>
            <LegalListItem>
              3. Our postal marketing material. By contacting us at:
              hello@geodb.com
            </LegalListItem>
          </ul>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>10. Communications about your account</LegalH2>
          <LegalText>
            We will contact you with information relevant to the operation and
            maintenance of your GeoDB account (including updated information
            about how we process your personal information), by a variety of
            means including via, email, text message, and in-app notifications.
            If at any point in the future you change your contact details you
            should tell us promptly about those changes. We may monitor or
            record calls, emails, text messages or other communications in
            accordance with applicable laws for the purposes outlined in
            Schedule A – Purposes of Processing.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>11. Fraud prevention</LegalH2>
          <LegalText>
            We may access and use information from fraud prevention agencies
            when you open your account and periodically to:
          </LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>
              A. manage and take decisions about your accounts;
            </LegalListItem>
            <LegalListItem>
              B. prevent criminal activity, fraud and money laundering; and
            </LegalListItem>
            <LegalListItem>
              C. check your identity and verify the accuracy of the information
              you provide to us;
            </LegalListItem>
          </ul>
          <LegalText>
            Application decisions may be taken based solely on automated checks
            of information, for example from fraud prevention agencies and
            internal GeoDB records. To help us make decisions on verifying your
            GeoDB account, as well as transaction limits on your GeoDB account,
            we look at information you give us when you apply for a GeoDB
            Account; including biometric data such as your photograph and/ or
            facial scan, information regarding your location, age, nationality
            and/ or citizenship and other information which enables us to verify
            your identity and perform a risk assessment for money laundering and
            fraud prevention purposes. You have rights in relation to the
            automated decision-making used in the verification process,
            including a right to attempt account verification again, or contact
            our Customer Support team if your application is refused. We will
            also profile your GeoDB Account to assign a risk rating for the
            purposes of fraud and unusual transaction monitoring and
            unauthorised access prevention.
          </LegalText>
          <LegalText>
            The information we will use to profile you will include your age,
            bank country of residence and status as a politically exposed person
            or otherwise. We will continue to collect and monitor information
            about how you manage your GeoDB account including your account
            balance, payments into your account, the regularity of payments
            being made, and any default in making payments, while you have a
            relationship with us. This information may be made available to
            other organisations (including fraud prevention agencies and other
            financial institutions) so that they can take decisions about you.
            If false or inaccurate information is provided and/or fraud is
            identified or suspected, details will be passed to relevant fraud
            prevention agencies. Law enforcement agencies and other
            organisations may access and use this information.
          </LegalText>
          <LegalText>
            We cooperate fully to the extent of our legal obligations in the
            prevention of fraud, money laundering and counter-terrorism. If we,
            or a fraud prevention agency, determine that you pose a fraud, money
            laundering or other criminal risk, we may refuse to provide the
            services you have requested, or we may stop providing existing
            services to you. A record of any fraud, money laundering or other
            criminal risk will be retained by the fraud prevention agencies, and
            may result in others refusing to provide services, financing or
            employment to you.
          </LegalText>
          <LegalText>
            Fraud prevention agencies can hold your information for different
            periods of time, and if you are considered to pose a fraud or money
            laundering risk, your data can be held for up to six years. When
            fraud prevention agencies process your information, they do so on
            the basis that they have a legitimate interest in preventing fraud
            and money laundering, and to verify your identity, in order to
            protect their business and to comply with laws that apply to them.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>12. How long we keep your information</LegalH2>
          <LegalText>
            By providing you with products or services, we create records
            containing your information, such as customer account records and
            activity and transaction records. Records can be held on a variety
            of media (physical or electronic) and formats, but they are
            primarily held electronically. We manage our records to help us to
            better serve our customers (for example for operational reasons,
            such as dealing with any queries relating to your account) and to
            comply with legal and regulatory requirements.
          </LegalText>
          <LegalText>
            Records help us demonstrate that we are meeting our responsibilities
            and to keep as evidence of our business activities. Retention
            periods for records are determined based on the type of record, the
            nature of the activity, product or service, the country in which the
            relevant GeoDB company is located and the applicable local legal or
            regulatory requirements. We (and other GeoDB group companies)
            normally keep customer account records for up to six years after
            your relationship with GeoDB ends. Retention periods may be changed
            from time to time (or waived where deemed low-risk) based on
            business or legal and regulatory requirements. Where there has been
            no activity on your GeoDB account since it was opened, we may delete
            your data after a period of 6 months, as you will be deemed an
            "unactivated user".
          </LegalText>
          <LegalText>
            If there has been any transactional activity on your account, we
            will maintain your data until you request that we delete it, unless
            we are obligated to maintain such data to comply with our legal
            obligations. We may on exception retain your information for longer
            periods than those stated above, particularly where we need to
            withhold destruction or disposal based on an order from the courts
            or an investigation by law enforcement agencies or our regulators.
            This is intended to make sure that GeoDB will be able to produce
            records as evidence, if they’re needed. If you would like more
            information about how long we keep your information, please contact
            us at: Hello@geodb.com
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>
            13. Scope of data processing, processing purposes and legal
          </LegalH2>
          <ul className="p-0 pt-3">
            <LegalListItem>
              Art. 6 para. 1 p. 1 it. a GDPR serves as our legal basis for
              processing operations for which we obtain consent.
            </LegalListItem>
            <LegalListItem>
              Art. 6 para. 1 s. 1 lit. b GDPR is the legal basis insofar as the
              processing of personal data is necessary for the performance of a
              contract, eg if a site visitor purchases a product from us or we
              perform a service for him. This legal basis also applies to
              processing that is necessary for pre-contractual measures, such as
              in the case of inquiries about our products or services.
            </LegalListItem>
            <LegalListItem>
              Art. 6 para. 1 s. 1 lit. c GDPR applies if we fulfill a legal
              obligation by processing personal data, as may be the case, for
              example, in tax law.
            </LegalListItem>
            <LegalListItem>
              Art. 6 para. 1 S. 1 lit. f GDPR serves as the legal basis when we
              can rely on legitimate interests to process personal data, eg for
              cookies that are necessary for the technical operation of our
              website.
            </LegalListItem>
          </ul>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>14. Data processing outside the EEA</LegalH2>
          <LegalText>
            Insofar as we transfer data to service providers or other third
            parties outside the EEA, the security of the data during the
            transfer is guaranteed by adequacy decisions of the EU Commission,
            insofar as they exist (eg for Great Britain) (Art . 45 para. 3
            GDPR). If no adequacy decision exists (eg for the USA), the legal
            basis for the data transfer are usually, ie unless we indicate
            otherwise, standard contractual clauses. These are a set of rules
            adopted by the EU Commission and are part of the contract with the
            respective third party. According to Art. 46 para. 2 lit. b GDPR,
            they ensure the security of the data transfer. Many of the providers
            have given contractual guarantees that go beyond the standard
            contractual clauses to protect the data. These include, for example,
            guarantees regarding the encryption of data or regarding an
            obligation on the part of the third party to notify data subjects if
            law enforcement agencies wish to access the respective data.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>15. Storage duration</LegalH2>
          <LegalText>
            Unless expressly stated in this privacy policy, the data stored by
            us will be deleted as soon as they are no longer required for their
            intended purpose and no legal obligations to retain data conflict
            with the deletion. If the data are not deleted because they are
            required for other and legally permissible purposes, their
            processing is restricted, ie the data are blocked and not processed
            for other purposes. This applies, for example, to data that must be
            retained for commercial or tax law reasons.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>16. Obligation to provide data</LegalH2>
          <LegalText>
            Unless this is expressly stated, the provision of the data is not
            required or obligatory.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>17. No automatic decision-making in individual case</LegalH2>
          <LegalText>
            As a matter of principle, we do not use a fully automated
            decision-making process in accordance with Article 22 GDPR to
            establish and implement the business or other relationship. Should
            we use these procedures in individual cases, we will inform of this
            separately if this is required by law.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>18. Contact</LegalH2>
          <LegalText>
            When contacting us, eg by e-mail, telephone or chat, the data
            provided to us (eg names and e-mail addresses) will be stored by us
            in order to answer questions. The legal basis for the processing is
            our legitimate interest (Art. 6 para. 1 p. 1 lit. f GDPR) to answer
            inquiries directed to us. We delete the data accruing in this
            context after the storage is no longer necessary or restrict the
            processing if there are legal retention obligations.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>19. Data processing in our app</LegalH2>
          <LegalH3>19.1 Registration</LegalH3>
          <LegalText>
            To be able to use Wallace Wallet completely, users don’t have to
            register. Nevertheless, we might ask users to register to some
            activities in which we might collect the email address and a user
            name that does not need to be the actual name.
          </LegalText>
          <LegalText>
            The legal basis for the processing is Art. 6 para. 1 s. 1 lit. b
            GDPR.
          </LegalText>
          <LegalH3>19.2 Social login</LegalH3>
          <LegalText>
            In order to facilitate the registration process in some of our
            features for our users, they might be offered the option of opening
            a user account via an existing account with a third-party provider.
            If a user decides to use this registration method, he/she will be
            redirected directly to the selected third-party provider in the
            first step. There, the user is requested by the respective
            third-party provider to enter the access data in order to log in or
            register with the respective service. If the user is already logged
            in to the third-party provider with the end device at this point,
            this login prompt is skipped. The Login options in the app can be
            recognized by the integrated logos of the respective third-party
            provider. Before a connection to the providers is established, the
            user must expressly agree to the process and transmission of data.
          </LegalText>
          <LegalText>
            If you use the option to register via one of the third-party
            providers, the app communicates with the servers of the respective
            provider, whereby name and email address are read and linked to the
            user account.
          </LegalText>
          <LegalH3>19.3 Selection information</LegalH3>
          <LegalText>
            The surveys in our App are directed to a panel that is selected
            based on age, gender and ZIP code of users. We will ask users for
            their age, gender and ZIP code in order to make available to them
            the relevant surveys for which they will receive rewards in the form
            or a decentralized token.
          </LegalText>
          <LegalText>
            Users can also use the app to answer individual questions that are
            asked separately from specific surveys, so-called "quickies". We
            will store some of the answers associated with the account. In this
            way, we classify the account into certain target group categories
            that result from the answers to the quickies. We also publish
            statistical data on individual Quickies in aggregate form - for
            example, what percentage of all users gave what answer to a Quickie.
            Conversely, users can also view the answers of the audience and
            compare them with their own answers. At no time will answers be
            visibly linked to any of the other data in such a way that other
            users can identify another user. Our partner companies have access
            to these created target group categories, but not to the data of the
            account.
          </LegalText>
          <LegalText>Legal basis is Art 6 (1) b GDPR.</LegalText>
          <LegalH3>19.4 Voluntary survey data</LegalH3>
          <LegalText>
            The answers that users provide us in a survey will be used to
            evaluate the results of this survey. In this way, we conduct
            targeted analyses and effective market research for our partner
            companies. Of course, for each survey users decide for themselves
            whether they want to participate in the survey and enter certain
            data. We process the data they enter statistically and pass the
            analysis on to our partner companies in an anonymous form that does
            not allow any conclusions to be drawn about individual users. Our
            partners do not have access to user data in raw form. The answers to
            a survey will not be stored in connection to the user accounts and
            we do not create a profile based on the answers in a survey.
          </LegalText>
          <LegalText>Legal basis is Art. 6 (1) b GDPR.</LegalText>
          <LegalH3>19.5 Surveys regarding special categories of data</LegalH3>
          <LegalText>
            Wallace Wallet app users have the option to provide a consent that
            allows us to provide them with surveys regarding so-called special
            categories of data (eg health). This allows us based on the answers
            to select matching users to a specific survey. By doing so, the user
            allows to be shown questions that are about health, for example.
          </LegalText>
          <LegalText>
            For the survey regarding special categories of data, users will be
            asked again for their consent to process those special categories of
            data. Consent is voluntary and can be withdrawn at any time by
            canceling the survey. In this case no special categories of data
            will be processed.
          </LegalText>
          <LegalText>
            Legal basis is the respective consent according to Art. 9 (2) a, 6
            (1) a GDPR.
          </LegalText>
          <LegalH3>19.6 Profile picture</LegalH3>
          <LegalText>
            Users can choose to have a profile picture that is displayed to
            other users. For this users can upload a picture from their
            smartphone or take a picture with their smartphone and use that. For
            these options, Wallace Wallet needs access to the user’s media
            content and the user’s camera. Wallace Wallet can and will use the
            allowed accesses only for the above mentioned functions. This serves
            the purpose of assisting the user in creating and managing the
            account. Legal basis is Art. 6 (1) b GDPR.
          </LegalText>
          <LegalH3>19.7 Answer to questions</LegalH3>
          <LegalText>
            Users can answer questions by clicking the presented buttons or
            typing in answers or by using the voice functioning or by taking a
            video of themselves. The voice functioning and the video will only
            start if you have chosen this function. The voice and the video will
            only be used for the evaluation of the survey. Legal basis is Art. 6
            (1) b GDPR.
          </LegalText>
          <LegalH3>
            19.8 Data processing in connection with push notifications
          </LegalH3>
          <LegalText>
            In order to inform you about news in connection with your app use,
            we send you push notifications. For this purpose, we analyse your
            user behaviour (e.g. who are your subscribers, which survey have you
            not yet taken part in) in order to offer you further information
            about these events (e.g. new survey of a subscriber). You can decide
            for yourself in the app settings which type of push notifications
            you want to receive. In addition, a push notification requires that
            you allow this app to send you notifications in your device
            settings. The data processing takes place because it is necessary
            for the fulfilment of the contract. This ensures the optimal use of
            the app. The legal basis is Art. 6 (1) b DSGVO.
          </LegalText>
          <LegalText>
            For push notifications, Firebase processes your personal data on our
            behalf. Firebase is a service provided by Google (Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA). Further
            information is available at
            https://firebase.google.com/terms/data-processing-terms.
          </LegalText>
          <LegalH3>
            19.9 Data processing in connection with the rewarding system
          </LegalH3>
          <LegalText>
            Wallace Wallet rewards users for the process of sharing anonymous
            data within the ecosystem. This data is used to create marketing
            audiences to which to impact with advertising campaigns. This
            advertising campaigns are managed by third party partners.
          </LegalText>
          <LegalText>Raw data is never sold or transferred.</LegalText>
          <LegalText>Data we collect:</LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>Activity timestamp</LegalListItem>
            <LegalListItem>Attributed click timestamp</LegalListItem>
            <LegalListItem>Android ID (Android only)</LegalListItem>
            <LegalListItem>Google Play Store advertising ID</LegalListItem>
            <LegalListItem>ID for advertisers (iOS only)</LegalListItem>
            <LegalListItem>Device operating system - Device type</LegalListItem>
            <LegalListItem>Full Device Information</LegalListItem>
            <LegalListItem>Installed Mobile Apps</LegalListItem>
            <LegalListItem>Device city</LegalListItem>
            <LegalListItem>Device two-character country code</LegalListItem>
            <LegalListItem>Device model number</LegalListItem>
            <LegalListItem>Device IP address</LegalListItem>
            <LegalListItem>Device internet service provider</LegalListItem>
            <LegalListItem>Facebook ad ID</LegalListItem>
            <LegalListItem>Facebook campaign ID</LegalListItem>
            <LegalListItem>Facebook ad set ID</LegalListItem>
            <LegalListItem>Hashed device ID</LegalListItem>
            <LegalListItem>
              Event names: Install (i.e., first app open, number of sessions
              recorded across entire user lifetime)
            </LegalListItem>
          </ul>
          <LegalText>The legal basis is Art. 6 (1) b DSGVO.</LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>20. Third parties</LegalH2>
          <LegalH3>20.1 Kochava</LegalH3>
          <LegalText>
            We use Kochava usage statistics and analysis technology provided by
            Kochava Inc., 201 Church St, Sandpoint, ID 83864, United States
            (’Kochava’). In case the user clicked on Wallace Wallet or third
            party ads related to Wallace Wallet, Kochava processes data related
            to user’s installation and first usage of our app and shares the
            data. The information that we gain from this function is used by us
            to understand how the user interacts with our mobile app after
            installation and to analyse online ad campaigns, mainly to determine
            which ads users interacted with before installing our app.
          </LegalText>
          <LegalText>
            The legal basis for the processing is Art. 6 para. 1 s. 1 lit. a
            GDPR. The processing is based on consent. The users can revoke their
            consent at any time in the settings of their mobile device. The
            revocation does not affect the lawfulness of the processing until
            the revocation.
          </LegalText>
          <LegalText>Data we collect:</LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>Activity timestamp</LegalListItem>
            <LegalListItem>Attributed click timestamp</LegalListItem>
            <LegalListItem>Android ID (Android only)</LegalListItem>
            <LegalListItem>Google Play Store advertising ID</LegalListItem>
            <LegalListItem>ID for advertisers (iOS only)</LegalListItem>
            <LegalListItem>Device operating system - Device type</LegalListItem>
            <LegalListItem>Full Device Information</LegalListItem>
            <LegalListItem>Installed Mobile Apps</LegalListItem>
            <LegalListItem>Device city</LegalListItem>
            <LegalListItem>Device two-character country code</LegalListItem>
            <LegalListItem>Device model number</LegalListItem>
            <LegalListItem>Device IP address</LegalListItem>
            <LegalListItem>Device internet service provider</LegalListItem>
            <LegalListItem>Facebook ad ID</LegalListItem>
            <LegalListItem>Facebook campaign ID</LegalListItem>
            <LegalListItem>Facebook ad set ID</LegalListItem>
            <LegalListItem>Hashed device ID</LegalListItem>
            <LegalListItem>
              Event names: Install (i.e., first app open, number of sessions
              recorded across entire user lifetime)
            </LegalListItem>
          </ul>

          <LegalH3>20.2 Firebase</LegalH3>
          <LegalText>
            To optimize our app - especially our user interface - we use
            Firebase from Google (Google LLC, 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA). Pseudonymized usage data is
            transmitted to Google. Google is a company based in the USA that
            analyzes usage data on our behalf. Data processing may take place
            outside the European Union. Google contractually assures us that it
            will comply with European data protection laws.
          </LegalText>
          <LegalText>
            For more information, please visit the
            https://firebase.google.com/support/privacy.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>Schedule A - Your rights</LegalH2>
          <LegalH3>Rights Description</LegalH3>
          <Row>
            <LegalColLeft>
              <strong>Access</strong> - You have a right to get access to the
              personal information we hold about you.
            </LegalColLeft>
            <LegalColRight>
              For more information on how to get access to your information and
              the documents we need you to submit, please email hello@geodb.com.
              You will be required to complete a form outlining your request and
              will also need to provide proof of your identity (to ensure we are
              dealing with the account owner).
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Rectification</strong> - You have a right to rectification
              of inaccurate personal information and to update incomplete
              personal information.
            </LegalColLeft>
            <LegalColRight>
              If you believe that any of the information that we hold about you
              is inaccurate, you have a right to request that we restrict the
              processing of that information and to rectify the inaccurate
              personal information. Please note that if you request us to
              restrict processing your information, we may have to suspend the
              operation of your account and/or the products and services we
              provide to you through the GeoDB app. For more information on how
              to rectify your information and the documents we need you to
              submit, please email hello@geodb.com. You will be required to
              complete a form outlining your request and will also need to
              provide proof of your identity (to ensure we are dealing with the
              account owner).
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Erasure</strong> - You have a right to request that we
              delete your personal information.
            </LegalColLeft>
            <LegalColRight>
              You may request that we delete your personal information if you
              believe that:
              <br /> A. we no longer need to process your information for the
              purposes for which it was provided; <br /> B. we have requested
              your permission to process your personal information and you wish
              to withdraw your consent; or <br />
              C. we are not using your information in a lawful manner. <br />{" "}
              Please note that if you request us to delete your information, we
              may have to suspend the operation of your account and/or the
              products and services we provide to you. For more information on
              how to request erasure of your information and the documents we
              need you to submit, please email hello@geodb.com. You will be
              required to complete a form outlining your request and will also
              need to provide proof of your identity (to ensure we are dealing
              with the account owner).
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Restriction</strong> - You have a right to request us to
              restrict the processing of your personal information.
            </LegalColLeft>
            <LegalColRight>
              You may request us to restrict processing your personal
              information if you believe that:
              <br /> A. any of the information that we hold about you is
              inaccurate; <br /> B. we no longer need to process your
              information for the purposes for which it was provided, but you
              require the information to establish, exercise or defend legal
              claims; or <br />
              C. we are not using your information in a lawful manner. <br />{" "}
              Please note that if you request us to restrict processing your
              information, we may have to suspend the operation of your account
              and/or the products and services we provide to you. For more
              information on how to request a restriction of processing of your
              information and the documents we need you to submit, please email
              hello@geodb.com. You will be required to complete a form outlining
              your request and will also need to provide proof of your identity
              (to ensure we are dealing with the account owner).
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Portability</strong> - You have a right to data
              portability.
            </LegalColLeft>
            <LegalColRight>
              Where we have requested your permission to process your personal
              information or you have provided us with information for the
              purposes of entering into a contract with us, you have a right to
              receive the personal information you provided to us in a portable
              format. You may also request us to provide it directly to a third
              party, if technically feasible. We’re not responsible for any such
              third party’s use of your account information, which will be
              governed by their agreement with you and any privacy statement
              they provide to you. If you would like to request a copy of the
              personal information you provided to us, email us at
              hello@geodb.com For more information on how to get access to your
              information and the documents we need you to submit, please email
              hello@geodb.com. You will be required to complete a form outlining
              your request and will also need to provide proof of your identity
              (to ensure we are dealing with the account owner).
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Objection</strong> - You have a right to object to the
              processing of your personal information.
            </LegalColLeft>
            <LegalColRight>
              You have a right to object to us processing your personal
              information (and to request us to restrict processing) for the
              purposes described in Section C of Schedule B – Purposes of
              Processing (below), unless we can demonstrate compelling and
              legitimate grounds for the processing, which may override your own
              interests, or where we need to process your information to
              investigate and protect us or others from legal claims. Depending
              on the circumstances, we may need to restrict or cease processing
              your personal information altogether or, where requested, delete
              your information. Please note that if you object to us processing
              your information, we may have to suspend the operation of your
              account and/or the products and services we provide to you.
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Marketing</strong> - You have a right to object to direct
              marketing.
            </LegalColLeft>
            <LegalColRight>
              You have a right to object at any time to processing of your
              personal information for direct marketing purposes, including
              profiling you for the purposes of direct marketing. For more
              information see Section 9.
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Withdraw consent</strong> - You have a right to withdraw
              your consent
            </LegalColLeft>
            <LegalColRight>
              Where we rely on your permission to process your personal
              information, you have a right to withdraw your consent at any
              time. We will always make it clear where we need your permission
              to undertake specific processing activities.
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              <strong>Lodge complaints</strong> - You have a right to lodge a
              complaint with the regulator.
            </LegalColLeft>
            <LegalColRight></LegalColRight>
          </Row>

          <LegalH2>Schedule B - Schedule of Purposes of Processing</LegalH2>
          <LegalText>
            We will only use and share your information where it is necessary
            for us to carry out our lawful business activities. Your information
            may be shared with and processed by other GeoDB group companies. We
            want to ensure that you fully understand how your information may be
            used. We have described the purposes for which your information may
            be used in detail in the table below:
          </LegalText>

          <LegalH3>12.1 Contractual Necessity</LegalH3>

          <Row>
            <LegalColLeft>
              We may process your information where it is necessary to enter
              into a contract with you for the provision of our products or
              services or to perform our obligations under that contract. Please
              note that if you do not agree to provide us with the requested
              information, it may not be possible for us to continue to operate
              your account and/or provide products and services to you. This may
              include processing to:
            </LegalColLeft>
            <LegalColRight>
              A. assess and process applications for products or services;{" "}
              <br />
              B. provide and administer those products and services throughout
              your relationship with GeoDB, including opening, setting up or
              closing your accounts or products; collecting and issuing all
              necessary documentation; executing your instructions; processing
              transactions, including transferring money between accounts;
              making payments to third parties; resolving any queries or
              discrepancies and administering any changes. Calls to our service
              centre and communications to our mobile and online helplines may
              be recorded and monitored for these purposes.
              <br />
              C. manage and maintain our relationships with you and for ongoing
              customer service. This may involve sharing your information with
              other GeoDB group companies to improve the availability of our
              services, for example enabling customers to visit branches of
              other GeoDB group companies;
              <br />
              D. administer any credit facilities or debts, including agreeing
              repayment options; and
              <br />
              E. communicate with you about your account(s) or the products and
              services you receive from us.
            </LegalColRight>
          </Row>
          <LegalH3>12.2 Legal obligation</LegalH3>
          <Row>
            <LegalColLeft>
              When you apply for a product or service(and throughout your
              relationship with us), we are required by law to collect and
              process certain personal information about you.Please note that if
              you do not agree to provide us with the requested information, it
              may not be possible for us to continue to operate your account and
              / or provide products and services to you.This may include
              processing to:
            </LegalColLeft>
            <LegalColRight>
              A. confirm your identity, including using biometric information
              and facial recognition technology and other identification
              procedures, for example fingerprint verification;
              <br />
              B. perform checks and monitor transactions and location data for
              the purpose of preventing and detecting crime and to comply with
              laws relating to money laundering, fraud, terrorist financing,
              bribery and corruption, and international sanctions. This may
              require us to process information about criminal convictions and
              offences, to investigate and gather intelligence on suspected
              financial crimes, fraud and threats and to share data with law
              enforcement and regulatory bodies;
              <br />
              C. share data with other financial institutions and third parties
              to help recover funds that have entered your account as a result
              of a misdirected payment by such a third party; <br />
              D. share data with police, law enforcement, tax authorities or
              other government and fraud prevention agencies where we have a
              legal obligation, including reporting suspicious activity and
              complying with production and court orders;
              <br />
              E. deliver mandatory communications to customers or communicating
              updates to product and service terms and conditions;
              <br />
              F. investigate and resolve complaints;
              <br />
              G. conduct investigations into breaches of conduct and corporate
              policies by our empoyees;
              <br />
              H. manage contentious regulatory matters, investigations and
              litigation;
              <br />
              I. perform assessments and analyse customer data for the purposes
              of managing, improving and fixing data quality;
              <br />
              J. provide assurance that GeoDB has effective processes to
              identify, manage, monitor and report the risks it is or might be
              exposed to;
              <br />
              K. investigate and report on incidents or emergencies on GeoDB’s
              properties and premises;
              <br />
              L. coordinate responses to business-disrupting incidents and to
              ensure facilities, systems and people are available to continue
              providing services; and <br />
              M. monitor dealings to prevent market abuse.
            </LegalColRight>
          </Row>
          <LegalH3>12.3 Legitimate Interests of GeoDB </LegalH3>
          <LegalText>
            We may process your information where it is in our legitimate
            interests to do so as an organisation and without prejudicing your
            interests or fundamental rights and freedoms.
          </LegalText>
          <Row>
            <LegalColLeft>
              We may process your information in the day-to-day running of our
              business, to manage our business and financial affairs and to
              protect our customers, employees and property. It is in our
              interests to ensure that our processes and systems operate
              effectively and that we can continue operating as a business. This
              may include processing your information to:
            </LegalColLeft>
            <LegalColRight>
              A. monitor, maintain and improve internal business processes,
              information and data, technology and communications solutions and
              services;
              <br />
              B. ensure business continuity and disaster recovery and responding
              to information technology and business incidents and emergencies;
              <br />
              C. ensure network and information security, including monitoring
              authorised users’ access to our information technology for the
              purpose of preventing cyber-attacks, unauthorised use of our
              communications systems and websites, prevention or detection of
              crime and protection of your personal data;
              <br />
              D. provide assurance on GeoDB’s material risks and reporting to
              internal management and supervisory authorities on whether GeoDB
              is managing them effectively;
              <br />
              E. perform general, financial and regulatory accounting and
              reporting;
              <br />
              F. protect our legal rights and interests; and
              <br />
              G. enable a sale, reorganisation, transfer or other transaction
              relating to our business.
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              It is in our interest as a business to ensure that we provide you
              with the most appropriate products and services and that we
              continually develop and improve as an organization. This may
              require processing your information to enable us to:
            </LegalColLeft>
            <LegalColRight>
              A. identify new business opportunities and to develop enquiries
              and leads into applications or proposals for new business and to
              develop our relationship with you;
              <br />
              B. send you relevant marketing information (including details of
              other products or services provided by us or other GeoDB group
              companies which we believe may be of interest to you);
              <br />
              C. understand our customers’ actions, behaviour, preferences,
              expectations, feedback and financial history in order to improve
              our products and services, develop new products and services, and
              to improve the relevance of offers of products and services by
              GeoDB group companies;
              <br />
              D. monitor the performance and effectiveness of products and
              services;
              <br />
              E. assess the quality of our customer services and to provide
              staff training. Calls to our Customer Support teams and
              communications to our mobile and online helplines may be recorded
              and monitored for these purposes;
              <br />
              F. perform analysis on customer complaints for the purposes of
              preventing errors and process failures and rectifying negative
              impacts on customers;
              <br />
              G. compensate customers for loss, inconvenience or distress as a
              result of services, process or regulatory failures;
              <br />
              H. identify our customers’ use of third-party products and
              services in order to facilitate the uses of customer information
              detailed above; and
              <br />
              I. combine your information with third-party data, such as
              economic data in order to understand customers’ needs better and
              improve our services.
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>
              We may perform data analysis, data matching and profiling to
              support decision-making with regards to the activities mentioned
              above. It may also involve sharing information with third parties
              who provide a service to us. It is in our interest as a business
              to manage our risk and to determine what products and services we
              can offer and the terms of those products and services. It is also
              in our interest to protect our business by preventing financial
              crime. This may include processing your information to:
            </LegalColLeft>
            <LegalColRight>
              A. carry out financial and insurance risk assessments;
              <br />
              B. manage and take decisions about your accounts;
              <br />
              C. carry out checks (in addition to statutory requirements) on
              customers and potential customers, business partners and
              associated persons, including performing adverse media checks,
              screening against external databases and sanctions lists and
              establishing connections to politically exposed persons;
              <br />
              D. share data with fraud prevention agencies and law enforcement
              agencies;
              <br />
              E. trace debtors and recovering outstanding debt;
              <br />
              F. for risk reporting and risk management.
            </LegalColRight>
          </Row>
        </div>
        <h1 style={headerStyle}>United Kingdom</h1>
      </Container>
    </>
  );
}
