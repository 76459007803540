import React from "react";
import { Container } from "react-bootstrap";
import {
  LegalH1,
  LegalH2,
  LegalH3,
  LegalListItem,
  LegalText,
} from "components/legal-layout";

const headerStyle = {
  fontFamily: "FK Grotesk Regular",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "32px",
  textAlign: "center",
  zIndex: 15,
};

export default function Cookies() {
  return (
    <>
      <Container className="container-xl mb-5">
        <LegalH1>Cookies policy</LegalH1>
        <div className="mt-4 p-3">
          <LegalText>
            Our website uses small data files called cookies to improve
            functionality and your user experience. A cookie is a small text
            file that a website saves on your computer or mobile device when you
            visit the site. It enables the website to remember your actions and
            preferences (such as login, language, font size and other display
            preferences) over a period of time, so you don’t have to keep
            re-entering them whenever you come back to the site or browse from
            one page to another.
          </LegalText>
          <LegalText>
            As described below, some cookies may collect personal information
            about you, as such, this policy should be read together with our
            PrivacyData Use and Retention Policy.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>
            By continuing to use our website you are agreeing to our use of the
            following cookies:
          </LegalH2>
          <LegalH3>
            Cookies that are strictly necessary to operate our website
          </LegalH3>
          <LegalText>
            Cookies to login, transact, and otherwise use our Website.
          </LegalText>
          <LegalH3>Cookies for analytical and performance purposes</LegalH3>
          <LegalText>
            Cookies to trackmetrics including visitors and actions on our
            website.
          </LegalText>
          <LegalH3>Cookies for targeting user actions</LegalH3>
          <LegalText>
            Cookies to track visits to the website and actions on the website,
            to make usage of the website and to make advertising and marketing
            more relevant for users. We may share this information with third
            parties.
          </LegalText>
          <LegalH3>Cookies from Third Parties</LegalH3>
          <LegalText>
            Cookies to track user information metrics, data, and where needed to
            provide the services of our website. Third Parties we use include:
          </LegalText>
          <ul className="p-0">
            <LegalListItem>
              Google Analytics: To remember traffic and visit information,
              determine the start and end of a session, and throttle the request
              rate.
            </LegalListItem>
            <LegalListItem>
              MailChimp: To send transactional emails to users.
            </LegalListItem>
            <LegalListItem>
              Twitter Universal Website Tag, Facebook Analytics & Google
              Adwords: For website conversion tracking and remarketing.
            </LegalListItem>
          </ul>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>How to control cookies:</LegalH2>
          <LegalText>
            You can choose which cookies to block or allow by changing your
            browser settings. Please note that some functions of our website
            require the use of cookies to work properly, and that by blocking
            cookies you may not be able to access all or some portions of our
            website.
          </LegalText>
        </div>
        <h1 className="" style={headerStyle}>
          United Kingdom
        </h1>
      </Container>
    </>
  );
}
