import React from 'react';
import { Container, Row } from 'react-bootstrap';
import {
  LegalColLeft,
  LegalColRight,
  LegalH1,
  LegalH2,
  LegalListItem,
  LegalText,
} from 'components/legal-layout';
import { Link } from "react-router-dom";
import 'styles/pages/Privacy.scss';

const backgroundTerms = {
    backgroundColor: "#171e26",
    color: "#FFFFFF",
    maxWidth: "100%",
    padding: "30px",
};


export default function Terms() {
  return (
    <>
      <Container className="container-xl" style={backgroundTerms}>
        <LegalH1>Terms and Policies</LegalH1>

        <div className="mt-4 p-3">
          <LegalH2>Region: United Kingdom</LegalH2>
          <LegalH2>Effective Date: January 2023</LegalH2>
          <LegalH2>1. Welcome</LegalH2>
          <LegalText>
            The Wallace non-custodial wallet (“Wallace Wallet App”) (described
            below) is provided to you by GeoDB Blockchain Limited, company
            incorporated and registered in United Kingdom, with registered company
            number 201927633E, whose registered office is at 1 North Bridge Road
            (“GeoDB”).
          </LegalText>
          <LegalText>
            These terms and conditions (“Terms” or “T&Cs”) will apply to your
            use of the Wallace Wallet App. These Terms constitute a legal
            agreement between you and GeoDB (unless otherwise specified) and
            govern your access to and use of the Wallace Wallet App and Account.
          </LegalText>
          <LegalText>
            Certain services offered through the Wallace Wallet App may be
            governed by separate terms and conditions. You acknowledge and agree
            that by accessing and using such services, you agree to be bound by
            such separate terms and conditions.
          </LegalText>
          <LegalText>
          The Wallace Wallet App is a non-custodial wallet, meaning you are solely in control of your Digital Assets. Wallace and GeoDB have no control over your NCW, and we cannot help you recover access to your NCW. Make sure to backup your access to your NCW, and ensure that you do not allow anyone else to access your NCW.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>2. Definitions</LegalH2>
          <Row>
            <LegalColLeft>“Affiliate”</LegalColLeft>
            <LegalColRight>
              means a corporation directly or indirectly, controlling,
              controlled by or under direct or indirect common control with
              another corporation;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Applicable Law”</LegalColLeft>
            <LegalColRight>
              means any law, rule, statute, subordinate legislation, regulation,
              by-law, order, ordinance, protocol, code, guideline, treaty,
              policy, notice, direction or judicial, arbitral, administrative,
              ministerial or departmental judgment, award, decree, treaty,
              directive, or other requirement or guideline published or in force
              at any time which applies to or is otherwise intended to govern or
              regulate any person (including all parties to this Terms),
              property, transaction, activity, event or other matter, including
              any rule, order, judgment, directive or other requirement or
              guideline issued by any governmental or regulatory authority;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Business Day(s)” </LegalColLeft>
            <LegalColRight>
              means any day except any Saturday, Sunday or public holiday which
              banking institutions are open for normal business activities
              in Croatia;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Dapp(s)” </LegalColLeft>
            <LegalColRight>
              has the meaning given to it in clause 3(i);
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“DeFi Earning” </LegalColLeft>
            <LegalColRight>
              means the feature offered in the Wallace Wallet App that allows
              users to place Digital Assets into one or more DeFi Protocols;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“DeFi Protocols” </LegalColLeft>
            <LegalColRight>
              means the decentralized finance projects offered by independent
              third parties;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“DeFi Swap” </LegalColLeft>
            <LegalColRight>
              has the meaning given to it in clause 3(h); 
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Digital Asset”</LegalColLeft>
            <LegalColRight>
              means any sort of cryptographic tokens, digital currencies,
              cryptocurrencies or virtual currencies;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Force Majeure Event”</LegalColLeft>
            <LegalColRight>
              means an event or failure which is beyond our reasonable control
              including (i) Acts of God, nature, court or government; (ii)
              failure or interruption in public or private telecommunication
              networks, Third-Party Blockchain Networks, communication channels
              or information systems; (iii) acts or omissions of acts of a party
              for whom we are not responsible; (iv) delay, failure or
              interruption in, or unavailability of, third party services and
              sites; (v) strikes, lockouts, labour disputes, wars, terrorist
              acts and riots; (vi) viruses, malware, other malicious computer
              codes or the hacking of any part of the Wallace Wallet and related
              applications or software;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“include/including”</LegalColLeft>
            <LegalColRight>means to include without limitation;</LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Instructions”</LegalColLeft>
            <LegalColRight>
              means all information, instructions (including transfer of funds
              instructions), communications, orders or messages given to us by
              you via the Wallace Wallet App;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“NCW”</LegalColLeft>
            <LegalColRight>means a non-custodian wallet;</LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“GeoDB”</LegalColLeft>
            <LegalColRight>means GeoDB and its Affiliates;</LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Network(s)”</LegalColLeft>
            <LegalColRight>
              means the decentralized network(s) of blockchains built by third
              parties;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Personal Data”</LegalColLeft>
            <LegalColRight>
              means any information relating to an identified or identifiable
              natural person; an identifiable natural person is one who can be
              identified, directly or indirectly, in particular by reference to
              an identifier such as a name, an identification number, location
              data, an online identifier or to one or more factors specific to
              the physical, physiological, genetic, mental, economic, cultural
              or social identity of that natural person;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Prohibited Countries”</LegalColLeft>
            <LegalColRight>
              means the countries or jurisdictions subject to prohibitions or
              restrictions on accessing or using the Wallace Wallet App and are
              as follows: any of the following: Afghanistan, the Democratic
              People’s Republic of Korea, Democratic Republic of Congo, Eritrea,
              Libya, Somalia, South Sudan, Sudan, Yemen, Iraq, Iran, Cuba,
              Syria, Mali, Central African Republic, Guinea-Bissau, Lebanon or
              any other country which (or with any other persons who) is
              sanctioned by the United Nations Security Council, or under
              applicable laws of your country of residence;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Recovery Phrase”</LegalColLeft>
            <LegalColRight>
              means the unique process and group of random words that allows a
              to import and recover his or her non-custodial wallet on a new
              device;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>"Services”</LegalColLeft>
            <LegalColRight>
              has the meaning given to it in clause 3;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Site”</LegalColLeft>
            <LegalColRight>
              means the website at https://wallacewallet.io which may change
              from time to time;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Staking”</LegalColLeft>
            <LegalColRight>
              has the meaning given to it in clause 3(f);
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Supported Cryptocurrency”</LegalColLeft>
            <LegalColRight>
              means all the cryptocurrencies listed on the Wallace Wallet App,
              which may be updated with or without notice at our sole discretion
              from time to time;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Taxes”</LegalColLeft>
            <LegalColRight>
              means any taxes, duties or fees that incurred, or required to be
              collected, paid or withheld for any reason in connection with your
              use of the Wallace Wallet under any Applicable Law;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Third-Party Blockchain Networks”</LegalColLeft>
            <LegalColRight>
              has the meaning given to it in clause 3(d);
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Third Party Materials”</LegalColLeft>
            <LegalColRight>
              has the meaning given to it in clause 12;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Validator”</LegalColLeft>
            <LegalColRight>
              means the validator run and managed by GeoDB through a third party
              service provider, responsible for validating transactions on
              Networks;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“GeoDB”</LegalColLeft>
            <LegalColRight>
              GeoDB Blockchain Limited a company incorporated under the laws of
              United Kingdom, registered company number 201927633EE
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Wallace Wallet Account”</LegalColLeft>
            <LegalColRight>
              means the account you must register in order to use
              the Wallace Wallet App;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Wallace Wallet App”</LegalColLeft>
            <LegalColRight>
            means the GeoDB wallet mobile application;
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“Wallet Extension”</LegalColLeft>
            <LegalColRight>
              has the meaning given to it in clause 3(i);
            </LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“we/us/our”</LegalColLeft>
            <LegalColRight>means the GeoDB; and</LegalColRight>
          </Row>
          <Row>
            <LegalColLeft>“you/your”</LegalColLeft>
            <LegalColRight>
              means the user(s) of the Wallace Wallet App.
            </LegalColRight>
          </Row>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>3. The Wallace Wallet App</LegalH2>
          <LegalText>
            Subject to clause 12, the GeoDB and/or the Third-Party
            Blockchain Networks provides the following services (collectively,
            the “Services”):
          </LegalText>
          <ul className="p-0 pt-3">
            <LegalListItem>
              (a) receive and send Supported Cryptoassets to other recipients;
            </LegalListItem>
            <LegalListItem>(b) buy and sell Digital Assets;</LegalListItem>
            <LegalListItem>
              (c) create a new Wallace Wallet, generate your NCW addresses and
              Recovery Phrase that you may use to import and recover your
              Wallace Wallet on a new device;
            </LegalListItem>
            <LegalListItem>
              (d) enable you to authorise transfer of Supported Cryptocurrencies with the passcode, biometrics or two-factor authentication (collectively “Codes”) you set, facilitate the submission of Supported Cryptocurrencies transaction data to the relevant blockchain networks (“Third-Party Blockchain Networks”);
            </LegalListItem>
            <LegalListItem>
              (e) enable you to use DeFi Earning which allow the users to earn
              Digital Assets from the DeFi Protocols;
            </LegalListItem>
            <LegalListItem>
              (f) enable you to use DeFi Earning which allows you to earn
              Supported Cryptocurrencies from staking Supported Cryptocurrencies
              with the Validator (“Staking”);
            </LegalListItem>
            <LegalListItem>
              (g) enable you to use the DeFi Swap protocol, which is a set of smart contracts on Third-Party Blockchain Networks that allows users to stake Supported Cryptocurrencies in certain smart contracts to swap and pool such Supported Cryptocurrencies (“DeFi Swap”).
            </LegalListItem>
            <LegalListItem>
              (h) enable you to use a decentralized exchange aggregator, which
              is a third party platform built on a Third-Party Blockchain
              Network that allows you to stake and swap Supported
              Cryptocurrencies; and
            </LegalListItem>
            <LegalListItem>
              (i) enable you to access a digital asset browser (“Wallet
              Extension”) and link to decentralized applications and
              decentralized exchanges (“Dapp(s)”).
            </LegalListItem>
            <LegalListItem>
              (j) enable you to earn supported cryptocurrencies based on your use of the Wallace Wallet App features and services, the data-sharing rewarding mechanism, the exclusive accepted user surveys, and the internal referral system;
            </LegalListItem>
          </ul>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>4. Onboarding </LegalH2>
          <LegalText>
          By signing up for the use of the Wallace Wallet App, you represent and warrant that: - you are at least eighteen (18) years of age; - you have the full right, power, and authority to agree to these Terms; - you are not resident or a Tax resident of, and do not otherwise have any relevant connection with, any Prohibited Countries; - you are not a resident or Tax resident of, and do not otherwise have any relevant connection with, any jurisdiction in which entry into or performing your obligations under these Terms is unlawful or restricted in any way or requires licensing, registration or approval of any kind; - you are not impersonating any other person, operating under an alias or otherwise concealing your identity; you are not located in, under the control of, or a national, citizen or resident of any Prohibited Countries; - you will not use the Wallace Wallet App  if any applicable laws in your country prohibit you from doing so in accordance with these Terms; and - you are compliant with all Applicable Law requirements to which you are subject, including without limitation, all tax laws and regulations, exchange control requirements and registration requirements.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>5. Your information</LegalH2>
          <LegalText>
          You agree to, at all times, cooperate with all requests made by us or any of our third-party service providers on our behalf in connection with your use of the Wallace Wallet App.
          </LegalText>
          <LegalText>
          Failure to provide up to date information may result in your inability to or adversely affect your use of the Wallace Wallet App. You undertake to indemnify us, our Affiliates and any third-party service providers for any and all losses and damage incurred as a result of your failure to provide complete, accurate and up to date information at any point during your use of the Wallace Wallet App.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>6. Fees</LegalH2>
          <LegalText>
          We may charge fees for some or part of the Services. Such fees may be subject to change at our sole discretion. The fees are disclosed at the time you access the Service and/or are available on the Site. You may incur charges from third parties for use of Third Party Materials, such as but not limited to the Dapps, Staking, DeFi Earning, you may access through your NCW. Such third party fees are not charged by Wallace.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>
            7. Limitation of services/Termination/Account closure
          </LegalH2>
          <LegalText>
          To the extent permitted by Applicable Law,  we or Wallace may at any time and without liability to, terminate, suspend or limit your use or any functionality of the Wallace Wallet App or your Wallace Wallet Account (such suspension or termination shall be determined by Wallace), under the following circumstances:
          </LegalText>
          <LegalListItem>
          (a) in the event of any breach by you of these Terms and any other terms and conditions referred to in these Terms; (b) for the purposes of complying with Applicable Laws or the prevention of criminal activities; (c) where we and/or Wallace suspects that you conduct any fraudulent or unlawful activities (including but not limited to money laundering, terrorism financing and fraudulent activities); (d) Where we are required to do so to comply with a court order, law, regulation, regulatory decree or ombudsman’s orders; or (e) where we and/or Wallace determine, in our sole discretion, that your use of your Wallace Wallet App and Wallace Wallet Account is detrimental or harmful to us and/or Wallace in any manner; or (f) it transpires that you have provided false, inaccurate, incomplete or misleading information; you fail to provide the required information for the ongoing due diligence process; or (g) to remedy the effects of any defect in or compromise to any information system upon which we and/or Wallace relies on; or (h) where you have been declared bankrupt; or (i) where you have been declared deceased.
          </LegalListItem>
          <LegalListItem>
          (b) If at any time you do not feel that you can agree to these Terms or any changes made to the Wallace Wallet App or your Wallace Wallet Account, you must immediately stop using and close your Wallace Wallet Account. You can close your Wallace Wallet Account at any time by withdrawing your Cryptoassets from your Wallace Wallet Account.
          </LegalListItem>
          <LegalListItem>
          (c) You will also lose your access to your Wallace Wallet Account if you terminate your use of the Wallace Wallet App or if we decide to withdraw the Wallace Wallet App altogether (in which case we'll give you two months' notice). You will not be entitled to any refund of fees for use of the Wallace Wallet App or Wallace Wallet Account (if any) or any other fees.
          </LegalListItem>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>8. Security</LegalH2>
          <LegalText>
          You are solely responsible and liable for keeping your Wallace Wallet Account safe (including details relating to your Codes and Recovery Phrase) to avoid loss of access to and/or control over your Wallace Wallet Account. We will not be liable to you for any loss of access to your Wallace Wallet Account should you lose your Codes. You are advised to maintain adequate security, measure and control of your Codes and access to and use of your Wallace Wallet Account. We will not be liable to you for any losses or damage resulting from any unauthorised access or use of your Wallace Wallet Account.
          </LegalText>
          <LegalText>
          The GeoDB does not receive or store your Codes or Recovery Phrase. We therefore, cannot assist with your Wallace Wallet App details retrieval. Neither can we provide a replacement of your Codes. In the event that you need to access a deceased’s Wallace Wallet Account, please contact Wallace. We may ask you for supporting documentation, such as a death certificate, probate documents, or other documents proving you have the right to administer the deceased’s estate.
          </LegalText>
          <LegalText>
          FAILURE TO KEEP YOUR WALLACE WALLET ACCOUNT SAFE OR REMEMBER YOUR WALLACE WALLET ACCOUNT DETAILS IN RELATION TO THE WALLACE WALLET APP MAY RESULT IN YOUR NCW ACCOUNT BECOMING PERMANENTLY INACCESSIBLE OR THE PERMANENT LOSS OF THE SUPPORTED CRYPTOCURRENCY STORED IN YOUR NCW ACCOUNT.
          </LegalText>
          <LegalText>
          If you believe your mobile, Wallace Wallet Account, or the login details for your Wallace Wallet Account have been lost, stolen, or accessed without authorisation, you must contact us immediately at hello@Wallacewallet.io or contacting the Help Center in the app. We and/or Wallace will not be responsible for losses incurred due to unauthorised access of your email.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>9. Data protection</LegalH2>
          <LegalText>
            BBy using the Wallace Wallet App, you confirm that you have read and accepted our Privacy Notice and understand how we and/or Wallace collect, use, disclose and share amongst ourselves your Personal Data and disclose such Personal Data to our authorised service providers and relevant third parties. For full and comprehensive information about when and why we collect personal information about you, how we use it, the conditions under which we may disclose it and how we keep it secure, please refer to our Privacy Notice, which is accessible at:
          </LegalText>
          <LegalText>
            <Link to="/privacy">https://wallacewallet.io/privacy</Link>
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>10. Electronic communications</LegalH2>
          <LegalText>
          You shall accept full responsibility for the security and authenticity of all Instructions and you shall be bound by all such Instructions You are aware that Instructions and information transmitted via the Wallace Wallet App are generally transmitted via the Internet and You acknowledge and agree that in the event of any dispute arising in connection with your use of the Wallace Wallet App , our records.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>
            11. Ownership and use of the NCW service provider technology
          </LegalH2>
          <LegalText>
          GeoDB owns all rights, title and interest in the Wallace Wallet App and the proprietary technology (unless otherwise specified), including our software (in source and object forms), algorithms, user interface designs, architecture, and documentation (both printed and electronic), network designs, know-how, and trade secrets, and including any modifications, improvements, and derivative work thereof (the “GeoDB Technology”). When you accept these Terms, we grant you a personal, non-exclusive, limited, revocable and non-transferable license to use the GeoDB Technology without the right to sublicense. You may only use the Wallace Wallet in a manner consistent with these Terms, and you shall not interfere with, disrupt, or cause damage to any other users of the Wallace Wallet App, produce any derivative work based on the  GeoDB Technology, nor shall you translate, reverse engineer, decompile or disassemble the GeoDB Technology.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>12. Third Party Services and Contents</LegalH2>
          <LegalText>
          Any transactions in relation to the Supported Cryptocurrencies must be confirmed and recorded on the relevant Third-Party Blockchain Networks. Such Third Party Blockchain Networks are decentralized, peer-to-peer networks and supported by independent third parties, which the GeoDB does not own, operate or has control over them. Therefore, we cannot and do not (i) ensure that the Instruction that you submit via the Wallace Wallet App to the Third-Party Blockchain Networks will be confirmed or processed; or (ii) assist you to cancel or modify your Instructions once the Instructions have been submitted via the Wallace Wallet App to the Third-Party Blockchain Networks.
          </LegalText>
          <LegalText>
          The Wallace Wallet App may include description or reference to a third party’s website, product or services, including Third-Party Blockchain Networks (“Third Party Materials”). 
When using a Dapp or other Third Party Materials through the Wallet Extension, you understand that you are interacting with Third Party Materials. We do not have control over the Third Party Materials, do not warrant or endorse, and are not responsible for the availability or legitimacy of the content, products or services on or accessible from, those Third Party Materials (including any related websites, resources or links displayed therein). You acknowledge sole responsibility for and assume all risk arising from your use of any Third Party Materials.
          </LegalText>
        </div>

        <div className="mt-4 p-3">
          <LegalH2>13. General disclaimer</LegalH2>
          <LegalText>
          You acknowledge and agree that by confirming the sending of your Supported Cryptocurrency you have checked and confirm that the recipient wallet address supports the type of cryptocurrency or token you are sending, is compatible with the token standard of the cryptocurrency or token you are sending, is a valid address, and is the correct recipient address. GeoDB is not liable for any losses of your Supported Cryptocurrency and will not assist in retrieving such lost Supported Cryptocurrency if you fail to do so.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>14. Warranty disclaimers </LegalH2>
          <LegalText>
          THE WALLACE WALLET APP AND RELATED SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE WALLACE WALLET APP AND UNDERLYING SOFTWARE OR ANY CONTENT ON THE SERVICES, WHETHER PROVIDED OR OWNED BY US OR BY ANY THIRD PARTY, INCLUDING WITHOUT LIMITATION, ANY WARRANTIES REGARDING QUALITY, STABILITY, SUITABILITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM VIRUS AND ERROR. IN ADDITION, WE DO NOT REPRESENT OR WARRANT THAT THE CONTENT ACCESSIBLE VIA THE WALLACE WALLET APP IS ACCURATE, COMPLETE, RELIABLE, CURRENT, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE RESULTS OF USING THE SERVICES WILL MEET YOUR PURPOSE OR REQUIREMENTS. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE WALLACE WALLET APP AND RELATED SERVICES ARE AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFECT IS WITH YOU.
          </LegalText>
          <LegalText>
          We shall not be liable for fault on the part of any third-party service providers instructed by us. In such cases, our liability shall be limited to using reasonable care in the selection, appointment and instruction of such third-party service providers (but not of any sub-contractor or other third party such third-party service provider may use).
          </LegalText>
          <LegalText>
          The parties agree that any claims against the other under these Terms may only be brought on an individual basis and not as a plaintiff or class member in any purported class or representative action or proceeding. No court or adjudicator may consolidate or join more than one person's or party's claims and may not otherwise preside over any form of a consolidated, representative, or class proceeding. Any relief awarded to you cannot and may not affect any other users.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>15. Limitation of liability </LegalH2>
          <LegalText>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR EMPLOYEES SHALL NOT BE LIABLE FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, OR ANY SIMILAR LOSSES OR DAMAGES (INCLUDING LOST PROFITS), COSTS AND FEES OR LIABILITY ARISING OUT OF TORT (INCLUDING NEGLIGENCE) RESULTING FROM THE USE OR INABILITY TO USE THE WALLACE WALLET APPLICATION FOR ANY INDIRECT, SPECIAL, PUNITIVE DAMAGES, STATUTORY, CONTRACTUAL OR OTHERWISE, OR VENUS ONLY, ARISING FROM OR IN CONNECTION WITH THE CONTENT, FEES, USE. WE ARE NOT RESPONSIBLE FOR:
          </LegalText>
          <LegalListItem>
            (A) FOR ANY FAILURES OR ERRORS IN SAID FUNCTIONS, OR ARISING OUT OF
            FAILURES OR ERRORS OF PERSONNEL;
          </LegalListItem>
          <LegalListItem>
            (B) FOR LINKS TO CONTENT AVAILABLE FOR THE APPLICATION;
          </LegalListItem>
          <LegalListItem>(C) FOR DELETION OF AN ACCOUNT;</LegalListItem>
          <LegalListItem>(D) DENIAL OF ACCOUNT DELETION;</LegalListItem>
          <LegalListItem>
            (E) FOR STORAGE, DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF
            CONTENT CONTAINED IN USING THE APP; AND
          </LegalListItem>
          <LegalListItem>
            (F) FOR EXCHANGING, BUYING, SELLING, SENDING, RECEIVING, STORING
            CRYPTO- ASSETS OR NON-CASH UNLESS WE HAVE DIRECTLY CONTRIBUTED TO
            THE CLAIM, INCLUDING USING THIRD-PARTY SERVICES THAT YOU USE TO DO
            SO USING OUR APP.
          </LegalListItem>
          <LegalText>
            IN ANY EVENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR
            TOTAL MAXIMUM AND AGGREGATE LIABILITY TO YOU FOR DIRECT DAMAGES
            SHALL NOT EXCEED 1% OF THE PRICE YOU PAID US (IF YOU PAID US) IN THE
            TWELVE MONTHS PRECEDING THE EVENT ALLEGED TO HAVE CAUSED THE CLAIM.
          </LegalText>
          <LegalText>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR PERSONNEL SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, EXEMPLARY, STATUTORY, INCIDENTAL OR CONSEQUENTIAL DAMAGES, OR ANY SIMILAR DAMAGES OR LOSSES (INCLUDING LOST PROFITS AND LOSS OF DATA), COSTS, EXPENSES AND FEES, WHETHER IN TORT (INCLUDING NEGLIGENCE), CONTRACT, OR ANY OTHER FORM OR THEORY OF LIABILITY, ARISING FROM OR RELATING TO THE CONTENTS, FEES, USE OR INABILITY TO USE SPATIUM OR ITS FUNCTIONS, NOTWITHSTANDING ANYTHING ELSE, WE ARE FULLY RELEASED FROM OUR OBLIGATIONS AND LIABILITY TO YOU IF YOU VIOLATE THESE TERMS AND CONDITIONS, ANY OTHER TERMS, RULES OR REGULATIONS APPLICABLE TO THE GEODB, OR IF YOU VIOLATE THE RIGHTS OF ANY OTHER PERSON AS A RESULT OF USING THE GEODB.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>16. Indemnification </LegalH2>
          <LegalText>
          You agree to indemnify and hold harmless the GeoDB, their affiliates and third-party service providers, and each of their officers, directors, agents, joint venture entities, employees and representatives, from any claim or demand (including attorneys' fees and any fines, fees or penalties imposed by any regulatory authority) arising out of or related to (a) your breach of any of these Terms; (b) your wrongful or improper use of the Wallace Wallet App; (c) any third party’s access or use of your Wallace Wallet Account, Codes, Recovery Phrase, NCW address information; or (d) your violation of any law, rule, regulation, or the rights of any third party.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>17.Apple App Store provisions </LegalH2>
          <LegalText>
          This paragraph 17 applies where the Wallace Wallet App has been downloaded from the Apple App Store. You acknowledge and agree that the Terms are solely between you and Wallace, not Apple, Inc. ("Apple") and that Apple has no responsibility for the App or content thereof. Your use of the Wallace Wallet App must comply with the App Store Terms of Service.
          </LegalText>
          <LegalText>
          You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Wallace Wallet App. In the event of any failure of the Wallace Wallet App to conform to any applicable warranty, you may notify Apple, and (where applicable) Apple will refund the purchase price for the Wallace Wallet App to you; to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Wallace Wallet App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be solely governed by the Terms and any law applicable to the GeoDB as provider of the Wallace Wallet App.
          </LegalText>
          <LegalText>
          You acknowledge that Apple is not responsible for addressing any claims of you or any third party relating to the Wallace Wallet App or your possession and/or use of the Wallace Wallet App, including, but not limited to: (i) product liability claims; (ii) any claim that the Wallace Wallet App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation; and all such claims are governed solely by the Terms and any law applicable to the GeoDB as provider of the software.
          </LegalText>
          <LegalText>
          You acknowledge that, in the event of any third-party claim that the Wallace Wallet App or your possession and use of that Wallace Wallet App infringes that third party's intellectual property rights, the GeoDB, not Apple, will be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim to the extent required by the Terms.
          </LegalText>
          <LegalText>
          You represent and warrant that (i) you are not located in a country that is subject to U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.
          </LegalText>
          <LegalText>
          You and Us acknowledge and agree that Apple, and Apple's subsidiaries, are third-party beneficiaries of the Terms of Service as relates to your license of the Wallace Wallet App, and that, upon your acceptance of the terms and conditions of the Terms of Service, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms of Service as relates to your license of the Wallace Wallet App against you as a third-party beneficiary thereof.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>18. Downloading our App from other App Stores</LegalH2>
          <LegalText>
          Where you download the Wallace Wallet App from any other app store or distribution platform other than the Apple App Store, including the Google Play Store (the "Distribution Platform") you agree that:
          </LegalText>
          <LegalListItem>
            (a) the Terms are between you and the GeoDB, and not with the provider of the Distribution Platform ("Store Provider");
          </LegalListItem>
          <LegalListItem>
            (b) your use of the Wallace Wallet App must comply with the Store
            Provider's then-current Distribution Platform Terms of Service;
          </LegalListItem>
          <LegalListItem>
            (c) the Store Provider is only a provider of the Distribution
            Platform where you obtained the Wallace Wallet App;
          </LegalListItem>
          <LegalListItem>
            (d) Wallace, and not the Store Provider, is solely responsible for
            the Wallace Wallet App;
          </LegalListItem>
          <LegalListItem>
            (e) the Store Provider has no obligation or liability to you with
            respect to the Wallace Wallet App or the Terms; and
          </LegalListItem>
          <LegalListItem>
            (f) you acknowledge and agree that the Store Provider is a
            third-party beneficiary to the Terms as it relates to the Wallace
            Wallet App
          </LegalListItem>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>19. No investment advice </LegalH2>
          <LegalText>
          We do not provide any type of investment advice. We may provide information concerning types of currencies and digital payment tokens,  prices, and events that may have influenced prices, all of which should not be considered investment advice. If you require investment advice you should contact a financial advisor. You are solely responsible for how you use our services and the financial results of your actions.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>20. Taxes </LegalH2>
          <LegalText>
          It is your sole responsibility to determine whether, and to what extent, any Taxes apply to your use of the Wallace Wallet App, and to withhold, collect, report and remit the correct amounts of such Taxes to the appropriate tax authorities. We are not obligated to, nor will we determine whether Taxes apply, or calculate, collect, report, or remit any Taxes to any tax authority arising from any transaction.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>21. Amendment and variation </LegalH2>
          <LegalText>
          These Terms may from time to time be updated or amended, and we will post any such updates on the Site. Such updated Terms as posted will take effect immediately unless otherwise indicated. You should regularly check the Site to inform yourself of any such changes. In addition, we may at any time change, add or remove any feature or functionality of the Wallace Wallet App without prior notice. By continuing to use the Wallace Wallet App after any such changes have taken effect, you are indicating your acceptance of the updated or amended Terms as well as your acceptance of the   do not wish to be bound by any changes or amendments to these Terms then you should stop using the Wallace Wallet App and immediately and inform Wallace via email at:
            <a href="mailto:hello@wallacewallet.io"> hello@wallacewallet.io</a>
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>22. Transfer, Assignment or Delegation </LegalH2>
          <LegalText>
          These Terms, and any rights and obligations and licences granted hereunder, are limited, revocable, non-exclusive and personal to you and therefore may not be transferred, assigned or delegated by you to any third-party without our written consent, but may be transferred, assigned or delegated by us without notice and restriction, including without limitation to any of the entities within the GeoDB, or to any successor in interest of any business associated with the GeoDB. Any attempted transfer or assignment in violation hereof shall be null and void.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>23. Severability </LegalH2>
          <LegalText>
          If any provision of these Terms shall be found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, the invalidity or unenforceability of such provision shall not affect the other provisions of these Terms and all provisions not affected by such invalidity or unenforceability shall remain in full force and effect. Such provision will be changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any Applicable Laws.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>24. Entire agreement</LegalH2>
          <LegalText>
          These Terms constitute the entire agreement between the parties with regard to its subject matter and supersedes and invalidates all other prior representations, arrangements, understandings, and agreements relating to the same subject matter, whether oral or in writing, express or implied. Each party acknowledges that in agreeing to these Terms it does not rely on any statement, representation, warranty, or understanding other than those expressly set out in these Terms.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>25. Waiver </LegalH2>
          <LegalText>
            These Terms shall not be waived in whole or in part except where
            agreed by all parties in writing.
          </LegalText>
          <LegalText>
          No failure or delay on the part of any party hereto in exercising any right, power or remedy hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any such right, power or remedy preclude any other or further exercise thereof or the exercise of any other right, power or remedy. No right, power or remedy conferred upon or reserved for any party in these Terms is exclusive of any other right, power or remedy available to that party and each such right, power or remedy shall be cumulative.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>26. Notices and communications </LegalH2>
          <LegalText>
          By using the Wallace Wallet App, you agree that we or Wallace may provide you with any notices or other communications, including marketing, relating to your use of the Wallace Wallet App electronically: (a) via email (in each case to the address that you provide), or (b) by posting to the Site. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted. You will always be given the option to unsubscribe from receiving any marketing material from us.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>27. Third party rights </LegalH2>
          <LegalText>
          Other than any entities within the GeoDB, a person who is not a party to these Terms has no right to enforce any of these Terms.
          </LegalText>
        </div>
        <div className="mt-4 p-3">
          <LegalH2>28. Governing law and dispute resolution </LegalH2>
          <LegalText>
          United Kingdom law will apply to all disputes and the interpretation of these Terms. The United Kingdom courts will have non-exclusive jurisdiction over any dispute arising from or related to your use of the Wallace Wallet App. This does not affect your rights under the law of the country in which you are resident, including (where applicable) your right to have a dispute in relation to your use of the Wallace Wallet App heard in the courts of that country.
          </LegalText>
        </div>
      </Container>
    </>
  );
}
