import './Text.scss';

const Text = ({ text }) => {
  return (
    <p className="default-text">
      { text }
    </p>
  );
}

export default Text;
