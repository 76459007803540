import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { translate } from 'i18n';
import 'styles/components/CookiesBox.scss';

const routeChange = () =>{ 
  let path = `cookies`; 
  window.open(path, '_blank', 'noopener,noreferrer');
}

const CookiesComponent = ({ handleAcceptCookies }) => (
  <div className="cookies-fixed-layer">
    <div className="cookies">
      <Card className="cookies-card">
        <div className="cookies-card__text">
          {translate('cookiesBox.message')}
        </div>
        <div className="cookies-card__buttons">
          <Button
            className="cookies-card__button"
            onClick={() => handleAcceptCookies()}
          >
            {translate('cookiesBox.accept')}
          </Button>
          <Button
            className="cookies-card__button cookies-card__button--outline"
            onClick={routeChange}
          >
            {translate('cookiesBox.knowmore')}
          </Button>
        </div>
      </Card>
    </div>
  </div>
);

export default CookiesComponent;
